import Home from './Home/Main';
import CreateGroup from './CreateGroupB/Main';
import Group from './Group/Main';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Auth, Hub } from 'aws-amplify';
import React, { useState, useEffect } from 'react';
import { AuthProvider } from './UserAuth/auth.js';
function ThemeColorProvider({ children }) {
    const location = useLocation();

    useEffect(() => {
        const color = /^\/group\//.test(location.pathname) ? '#98E3F4' : '#ffffff';
        const metaTag = document.querySelector('meta[name="theme-color"]');
        if (metaTag) {
            metaTag.content = color;
        }
    }, [location.pathname]);

    return children;
}



function App() {


    const [user, setUser] = useState(null);

    const [hasCreatedGroup, setHasCreatedGroup] = useState(false);
    const userAgent = navigator.userAgent;
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
    //const isMobile = true;

    // useEffect(() => {
    //     document.documentElement.requestFullscreen().then(() => {
    //       window.screen.orientation.lock('portrait');
    //     }).catch(err => {
    //       console.log(err);
    //     });
    // }, []);

    useEffect(() => {
        

        if (!isMobile) {
            // If not a mobile device, redirect to the specified URL
            window.location.replace('https://letsgodutch.app');
        }
    }, []);

    useEffect(() => {
        console.log("Created goruo")
        console.log(hasCreatedGroup)
    }, [hasCreatedGroup]);


    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    useEffect(() => {
        // Function to load current user
        const loadCurrentUser = async () => {
            try {
                const userInfo = await Auth.currentAuthenticatedUser();
                setUser(userInfo);
            } catch (err) {
                console.log("User not logged in")
                console.log(err);
                setUser(null);
            }
        };

        // Load current user on component mount
        loadCurrentUser();

        // Listen for sign in and sign out events
        const listener = Hub.listen('auth', (data) => {
            switch (data.payload.event) {
                case 'signIn':
                    loadCurrentUser();
                    break;
                case 'signOut':
                    setUser(null);
                    break;
                default:
                    break;
            }
        });

        // Clean up listener on component unmount
        return () => Hub.remove('auth', listener);
    }, []);

    function updateLocalStorage(accessToken, groupID, username, password) {
        console.log("UPDATING LOCAL STORAGE")
        localStorage.setItem('AccessToken', accessToken);
        localStorage.setItem('groupID', groupID);
        localStorage.setItem('username', username);
        localStorage.setItem('password', password);
    }




    return (
        <>
        { isMobile ?
        (<AuthProvider>
            <Helmet>

                <meta property="og:title" content="DineDivide" />
                <meta property="og:description" content="Pay in one, split separately! Quick, easy and fair restaurant bill splitting application." />



            </Helmet>
            <BrowserRouter>
                <ThemeColorProvider></ThemeColorProvider>
                <Routes>
                    <Route path='/' exact element={<Home user={user} updateLocalStorage={updateLocalStorage} />} />
                    <Route path='/create-group' element={<CreateGroup user={user} updateLocalStorage={updateLocalStorage} setHasCreatedGroup={setHasCreatedGroup} />} />
                    <Route path='/group/:id' element={<Group updateLocalStorage={updateLocalStorage} hasCreatedGroup={hasCreatedGroup} setHasCreatedGroup={setHasCreatedGroup} />} />
                </Routes>
            </BrowserRouter>
        </AuthProvider>) :
        (<h1>Redirecting...</h1>)
        }
        </>
    );
}

export default App;