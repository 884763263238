import "../../style.css";
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import ScaleLoader from "react-spinners/ScaleLoader";
import { EditItem, DeleteItem } from '../../ApiCalls/Api';


export default function EditItems({ roomId, items, queryBackend }) {
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [provisionalItemName, setProvisionalItemName] = useState("");
    const [provisionalItemTotalPrice, setProvisionalItemTotalPrice] = useState("");

    const [isWaitingEdit, setIsWaitingEdit] = useState(false);
    const [isWaitingDeleteArray, setIsWaitingDeleteArray] = useState(new Array(items.length).fill(false));

    const isOnlyOneItem = items.length === 1;

    function handleItemChange(index, field, value) {
        //console.log("handleItemChange");
    }

    function handleEditItem(index, itemName, itemPrice) {
        setSelectedIndex(index);
        setProvisionalItemName(itemName);
        setProvisionalItemTotalPrice(itemPrice);
    }

    async function handleDeleteItem(index, itemID) {
        if (window.confirm('Are you sure you want to delete this item?')) {
            let newArr = [...isWaitingDeleteArray];
            newArr[index] = true;
            setIsWaitingDeleteArray(newArr);

            await DeleteItem(roomId, itemID);
            await queryBackend();

            newArr[index] = false;
            setIsWaitingDeleteArray(newArr);
            setSelectedIndex(-1);
        }
    }


    async function handleSaveEditItem(itemID) {
        if (window.confirm("Are you sure you want change this item to be named \"" + provisionalItemName + "\" with a price of " + provisionalItemTotalPrice)) {
            setIsWaitingEdit(true)
            await EditItem(roomId, itemID, provisionalItemName, provisionalItemTotalPrice)
            await queryBackend();
            setIsWaitingEdit(false)
            setSelectedIndex(-1);
        }
    }

    return (
        <>
            <div className="edit-items">
                <ul style={{ width: "100%", height: "100%", listStyle: 'none', overflow: 'scroll', margin: "0px" }}>
                    {items.map((item, index) => (
                        <li key={index} style={{ width: "100%", borderBottom: "1px solid gray" }}>
                            <div className='bill-item-edit'>
                                {selectedIndex === index ? (
                                    <div className='bill-item-edit-Left'>
                                        <div className='nameAndTotalPrice'>
                                            <input className='billImageCropperEditItemName' value={provisionalItemName} onChange={(e) => setProvisionalItemName(e.target.value)} />
                                        </div>
                                        <div className='bill-item-edit-price'>
                                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                <h4>Price </h4><input className='billImageCropperEditItemTotalPrice' value={provisionalItemTotalPrice} onChange={(e) => setProvisionalItemTotalPrice(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className='bill-item-edit-Left'>
                                        <div className='bill-item-edit-name'>
                                            <h3 className='nameAndTotalPrice1'>{item.itemName}</h3>
                                        </div >

                                        <div className='bill-item-edit-price'>
                                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                <h4>Price </h4><h3 style={{ paddingRight: "5%" }}>{parseFloat(item.price).toFixed(2)}</h3>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                <div className='bill-item-edit-Right'>
                                    {selectedIndex === index ? (
                                        <>
                                            <button style={{ backgroundColor: "green" }} onClick={() => setSelectedIndex(-1)}>
                                                <img className='provsionalItemsButtons' src={require('../../../assets/edit.png')} />
                                            </button>
                                            <button
                                                onClick={() => handleDeleteItem(index, item.id)}
                                                disabled={isOnlyOneItem} // Disable button if only one item is present
                                                style={{
                                                    backgroundColor: isOnlyOneItem ? "lightgrey" : "red",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center"
                                                }}>
                                                {isWaitingDeleteArray[index] ?
                                                    <ScaleLoader color={isOnlyOneItem ? "black" : "white"} height="10px" /> :
                                                    <img className='provsionalItemsButtons' src={require('../../../assets/bin.png')} />
                                                }
                                            </button>
                                        </>
                                    ) : (
                                        <>
                                            <button onClick={() => handleEditItem(index, item.itemName, item.price)}>
                                                <img className='provsionalItemsButtons' src={require('../../../assets/edit.png')} />
                                            </button>
                                            <button
                                                onClick={() => handleDeleteItem(index, item.id)}
                                                disabled={isOnlyOneItem} // Disable button if only one item is present
                                                style={{
                                                    backgroundColor: isOnlyOneItem ? "lightgrey" : "red",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center"
                                                }}>
                                                {isWaitingDeleteArray[index] ?
                                                    <ScaleLoader color={isOnlyOneItem ? "black" : "white"} height="10px" /> :
                                                    <img className='provsionalItemsButtons' src={require('../../../assets/bin.png')} />
                                                }
                                            </button>
                                        </>
                                    )}
                                </div>
                            </div>
                            {selectedIndex == index &&
                                <div className='bill-item-edit-Submit'>
                                    <button style={{ display: "flex", justifyContent: 'center', alignItems: "center", width: "94%" }} onClick={() => (handleSaveEditItem(item.id))}>{isWaitingEdit ? <ScaleLoader color="white" height="19px" /> : <>Submit</>} </button>
                                </div>
                            }
                        </li>
                    ))}

                </ul>
            </div>
        </>
    );
}