import React, { useState, useRef, useEffect } from 'react';
import '../style.css';
import Switch from 'react-switch';
import ChangeApplied from "../../utils/ChangeApplied";

export default function CreateGroupOptionsMenu({ handleCloseOptions, password, setPassword, setAdminAccess, adminAccessEnabled, serviceChargeBox, taxBox, discount, setDiscount}){
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [tempPassword, settempPassword] = useState(password);
    const [tempDiscount, settempDiscount] = useState(discount);
    const [showApplied, setShowApplied] = useState(false);
    const [visible, setVisible] = useState(false);

    useEffect(() => {
      setVisible(true);
      return () => {
        setVisible(false);
      };
    }, []);


    const handleOptionClick = (index) => {
        setSelectedIndex(index);
    };
  
    const handlePasswordChange = () => {
      setPassword(tempPassword);
      setShowApplied(true);
    };

    const handleDiscountInputChange = (event) => {
      settempDiscount(event.target.value);
    };


    return (
      <div className={`optionsCreateGroup ${visible ? 'visible' : ''}`}>
        <div className='optionsTitle'>
          <div style={{ width: '10%' }}></div>
          <div
            style={{ width: '80%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <h1>Options</h1>
          </div>
          <div className='closeButtonDiv' style={{ width: '10%' }}>
            <button onClick={() => handleCloseOptions(false)} className='closeButton'>
              <img className='closeButtonImage' src={require('../../assets/close.png')} />
            </button>
          </div>
        </div>
        <div style={{ marginTop: '10px', width: '80%' }} className='div-ForLine'></div>
        <div className='optionsContent'>
          <ul style={{height : "100%"}}>
{/* 
            <li className='option' onClick={() => handleOptionClick(0)}>
              <h3>Set Group Password</h3>
              {selectedIndex === 0 && (
                <div style={{display: "flex", width: "80%" , justifyContent: 'center', alignContent: 'center', flexDirection: 'column', marginBottom: "10px"}}>
                  <input style={{marginBottom : "10px"}} type='text' placeholder='Enter password' value={tempPassword} onChange={e => settempPassword(e.target.value)} />
                  <button onClick={handlePasswordChange}>Apply</button>
                </div>
              )}
            </li> */}
            
            <li className='option' onClick={() => handleOptionClick(1)}>
                <h3>Service Charge</h3>
                {selectedIndex === 1 && (
                    React.cloneElement(serviceChargeBox, { setShowChangeApplied: setShowApplied })
                  )}
            </li>


            <li className='option' onClick={() => handleOptionClick(2)}>
                <h3>Tax</h3>
                {selectedIndex === 2 && (
                    React.cloneElement(taxBox)
                  )}
            </li>

            <li className='option' onClick={() => handleOptionClick(3)}>
                <h3>Discounts</h3>
                {selectedIndex === 3 && (
                <div style={{display: "flex", width: "100%" , justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginBottom: "10px"}}>
                  <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: 'center'}}>
                    <h3>Discount Percentage</h3>
                    <input style={{width: "20%"}} type='text' inputMode='decimal'  value={tempDiscount} onChange={handleDiscountInputChange} />
                  </div>
                  <button onClick={event => (setDiscount(tempDiscount), setShowApplied(true))}>Apply</button>
                </div>
              )}
            </li>



          </ul>
        </div>
        { showApplied &&
          <ChangeApplied setShow={setShowApplied}/>
        }
        
      </div>
    );
}