import React, { useState, useEffect } from 'react';
import AnimateHeight from "react-animate-height";
import ClaimerIcon from '../ClaimerIcon/ClaimerIcon';
import ScaleLoader from "react-spinners/ScaleLoader";

import { UnClaimItem } from '../ApiCalls/Api';

export default function VerboseListItem({item, discount, serviceCharge, excludedTax, splitSCEqually, roomID, fetchFunc, setIsWaiting, index, selectedIndex, setSelectedIndex, tip}) {
    
    const [isWaitingClaim, setIsWaitingClaim] = useState(false);

    const handleUnclaimItem = async () => {
        const confirmed = window.confirm("Are you sure you want to unclaim this item?");
        if (confirmed) {
            setIsWaiting(true);
            setIsWaitingClaim(true);
            await UnClaimItem(roomID, item.id);
            setIsWaiting(false);
            
            await fetchFunc();
            setIsWaitingClaim(false);
            setSelectedIndex(-1);
        }
    };


    const [height, setHeight] = useState("auto");

    useEffect(() => {
        const element = document.getElementById(`verboseItem${index}`);
        const clone = element.cloneNode(true); 
        clone.style.height = "auto";
        clone.style.position = "absolute"; 
        clone.style.visibility = "hidden"; 
        element.parentNode.appendChild(clone); 
        const newHeight = clone.clientHeight + 3;
        element.parentNode.removeChild(clone); 
        setHeight(newHeight);
    }, [selectedIndex, index, item.claimees.length]);

    function ExtraInfo({item}) {
        const infoList = [];
        
        
    
        if (discount > 0) {
          infoList.push(["Discount", `- ${parseFloat(item.price * (discount / 100)).toFixed(2)}`]);
        }
        if (serviceCharge > 0  && !splitSCEqually) {
          infoList.push(["+ Service Charge", `+ ${parseFloat((item.price * (1 - discount / 100)) * (serviceCharge / 100)).toFixed(2)}`]);
        }
        if (excludedTax > 0) {
          infoList.push(["+ Tax", `+ ${parseFloat((item.price * (1 - discount / 100)) * (excludedTax / 100)).toFixed(2)}`]);
        }
        if (tip > 0) {
            infoList.push(["+ Tip", `+ ${parseFloat((item.price * (1 - discount / 100)) * (tip / 100)).toFixed(2)}`]);
        }
        
        return (
          <>
            {infoList.map((info, index) => (
              <div className='user-list-bill-item-horizantal' key={index}>
                <p className="servicechargeAndTaxBillItem">{info[0]}</p>
                <p className="servicechargeAndTaxBillItem">{info[1]}</p>
              </div>
            ))}
          </>
        );
    }

    return (
        <li style={{width: "100%"}} className="item-Display-Group" onClick={() => setSelectedIndex(index)}>
            <div className="user-list-bill-item">
            <AnimateHeight style={{width: "100%"}} id={"verboseItem" + index} duration={500} height={height}>
                
                    <div className='user-list-bill-item-horizantal' 
                        style={{whiteSpace: selectedIndex === index ? "normal" : "nowrap",
                                overflow: selectedIndex === index ? "visible" : "hidden"
                    }}
                        onClick={(event) => { 
                            event.stopPropagation();
                            selectedIndex != index ? setSelectedIndex(index) : setSelectedIndex(-1); 
                            
                        }}>
                        <h3 style={{ overflow: selectedIndex === index ? "visible" : "hidden", width: "75%", textOverflow: "ellipsis"}}>{item.itemName}</h3>
                        <h4>{parseFloat(item.price).toFixed(2)}</h4>
                    </div>

                    <ExtraInfo item={item} />

                    {item.claimees.length > 1 && (
                        <>
                        <div className='user-list-bill-item-horizantal'>
                            <h4>Net Price </h4>
                            <h4>
                                {parseFloat((item.price * (1 - discount / 100)) * (1 + serviceCharge / 100) + (item.price * (1 - discount / 100)) * (excludedTax / 100) + (item.price * (1 - discount / 100)) * (tip / 100)).toFixed(2)}
                            </h4>
                        </div>
                        <div className='user-list-bill-item-horizantal'>
                            <p>Shared by </p>
                            <p>{item.claimees.length}</p>
                        </div>
                        
                        <div className='user-list-bill-item-horizantal'>
                            <h3>Final Price</h3>
                            <h2>
                                {parseFloat((((item.price * (1 - discount / 100)) * (1 + serviceCharge / 100) + (item.price * (1 - discount / 100)) * (excludedTax / 100)) + (item.price * (1 - discount / 100)) * (tip / 100)) / item.claimees.length).toFixed(2)}
                            </h2>
                        </div>

                        </>
                    )}
                    {item.claimees.length === 1 && (
                        <div className='user-list-bill-item-horizantal'>
                            <h3>Final Price</h3>
                            <h2>
                                {parseFloat((item.price * (1 - discount / 100)) * (1 + serviceCharge / 100) + (item.price * (1 - discount / 100)) * (excludedTax / 100) + (item.price * (1 - discount / 100)) * (tip / 100)).toFixed(2)}
                            </h2>
                        </div>
                    )}

                    {selectedIndex == index && (
                        <div style={{ display: "flex", justifyContent: "center", paddingTop: "3px", paddingBottom: "5px" }}>

                            <button style={{backgroundColor:"red", width: "60%"}} onClick={handleUnclaimItem} disabled={isWaitingClaim}>{isWaitingClaim ? <ScaleLoader color="white" height="14px" /> : <>Un-Claim Item</>}</button>

                        </div>
                    )}
                
            </AnimateHeight>
            </div>
        </li>
    );
};
