import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import './style.css';

import SignIn from './SignIn';
import SignUp from './SignUp';

export default function AuthPopUp({setShow}) {
    const [visible, setVisible] = useState(false);
    const [isSigningUp, setIsSigningUp] = useState(false);
    const [signedUp, setSignedUp] = useState(false);


    useEffect(() => {
        setVisible(true);
        return () => {
            setVisible(false);
        };
    }, []);

    return (
        <>
            <div className={`disabledBackground ${visible ? 'greyedOutvisible' : ''}`}></div>
            <div className="AuthPopUp">
                <div className='top'>
                        <button onClick={() => setShow(false)} style={{width: "25px", height: "25px"}} className='closeButton'>
                            <img className='closeButtonImage' src={require('../assets/close.png')} />
                        </button>
                </div>
                {isSigningUp ?
                    <div className="AuthPopUpMain">
                        
                        
                        {!signedUp ? 
                            <>
                                <SignUp setSignedUp={setSignedUp}/>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'spaceBetween', width: '100%' }}>
                                    <hr style={{ width: '40%', borderColor: 'grey', borderWidth: '1px' }} />
                                    <p>OR</p>
                                    <hr style={{ width: '40%', borderColor: 'grey', borderWidth: '1px' }} />
                                </div>
                                <button onClick={() => setIsSigningUp(false)}>Back to Sign In</button>
                            </>
                        :
                            <>
                                <h3>You have successfully signed up! You can now sign in.</h3>
                                <button onClick={() => setIsSigningUp(false)}>Back to Sign In</button>
                            </>
                            
                        }
                    </div>
                    :
                    <div className="AuthPopUpMain" >
                        <SignIn setShow={setShow}/>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'spaceBetween', width: '100%' }}>
                            <hr style={{ width: '40%', borderColor: 'grey', borderWidth: '1px' }} />
                            <p>OR</p>
                            <hr style={{ width: '40%', borderColor: 'grey', borderWidth: '1px' }} />
                        </div>
                        <button onClick={() => setIsSigningUp(true)}>Register</button>
                    </div>
                }

            </div>

        </>
    )
}


