import React, { useState } from 'react';
import ScaleLoader from "react-spinners/ScaleLoader";

import { useAuth } from './auth'; // Import useAuth from your AuthProvider

function SignUp({ setSignedUp }) {
    const { signUp, confirmSignUp } = useAuth(); // Destructure the signUp method from useAuth
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [name, setName] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [confirmationCode, setConfirmationCode] = useState('');
    const [isWaiting, setIsWaiting] = useState(false);
    const [hasGivenEmail, setHasGivenEmail] = useState(false);

    // Adjusting the sign-up function to work with the AuthProvider
    const handleSignUp = async () => {
        try {
            setIsWaiting(true);
            await signUp(email, name); // Use the signUp method from useAuth
            setHasGivenEmail(true);
            setIsWaiting(false);
        } catch (error) {
            setErrorMessage(error.message);
            setIsWaiting(false);
        }
    };

    // Assume you have a confirmSignUp function in your AuthProvider to handle the confirmation code
    const handleConfirmSignUp = async () => {
        try {
            setIsWaiting(true);
            await confirmSignUp(email, confirmationCode);
            setIsWaiting(false);
            setSignedUp(true); // Update the state to reflect that the user has signed up and confirmed.
        } catch (error) {
            setErrorMessage(error.message);
            setIsWaiting(false);
        }
    };
    const isValidForm = email.length > 0 && name.length > 0 && name.includes(" ");

    return (
        <div className="inputs">
            {hasGivenEmail ?
                <>
                    <h3>Enter the confirmation code sent to your email: {email}</h3>
                    <input
                        value={confirmationCode}
                        onChange={e => setConfirmationCode(e.target.value)}
                        placeholder="Confirmation Code"
                    />
                    <button onClick={handleConfirmSignUp} disabled={confirmationCode.length === 0 || isWaiting}>
                        {isWaiting ? <ScaleLoader color="white" height="18px"/> : 'Confirm'}
                    </button>
                </>
                :
                <>
                    <input
                        value={name}
                        onChange={e => setName(e.target.value)}
                        placeholder="Full Name*"
                    />
                    <input
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        placeholder="Email*"
                    />
                    <button onClick={handleSignUp} disabled={!isValidForm}>
                        {isWaiting ? <ScaleLoader color="white" height="18px"/> : 'Sign Up'}
                    </button>
                    {errorMessage && <p style={{color: 'red', marginBottom: '0px'}}>{errorMessage}</p>}
                </>
            }
        </div>
    );
}

export default SignUp;
