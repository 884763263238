import '../style.css';
import React, { useState, useRef, useEffect } from 'react';
import MoonLoader from "react-spinners/MoonLoader";

export default function ConfirmCreationBox({createGroup, setConfirmCreationBox, billTotal}){

    const [visible, setVisible] = useState(false);
    const [isWaiting, setIsWaiting] = useState(false);

    useEffect(() => {
      setVisible(true);
      return () => {
        setVisible(false);
      };
    }, []);

    async function handleCreateGroup(){
        setIsWaiting(true);
        await createGroup();
        setIsWaiting(false);
    }

    return (
        <>
            <div className={`disabledBackground ${visible ? 'greyedOutvisible' : ''}`}>
            </div>
            <div className="confirmCreation">
                <div className="confirmCreation-content">
                    <div className="confirmCreation-header">
                        <h1 style={{margin: "0"}}>Confirm</h1>
                    </div>
                    {
                        isWaiting &&
                        <MoonLoader />
                    }
                    {
                        !isWaiting &&
                        <>
                        <div className="confirmCreation-body">
                            <p style={{marginBottom: "0"}}>Please make sure the total is correct</p><p style={{marginTop: "0"}}> If not go back to fix</p>
                        </div>
                        <div className="confirmCreation-billTotal">
                            <h1 style={{marginTop : "0"}} >Total : {billTotal}</h1>
                        </div>
                        </>
                    }
                    
                    <div className="confirmCreation-footer">
                        <button className="confirmCreation-button" onClick={() => setConfirmCreationBox(false)}>Back</button>
                        <button className="confirmCreation-button" onClick={() => handleCreateGroup()}>Continue</button>
                    </div>
                </div>
            </div>
        </>
    )
}