import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import AvatarEditor from 'react-avatar-editor'
import './style.css';
import { HeightAnimator } from 'animate-height';
import AuthPopUp from '../UserAuth/AuthPopUp';
import UserPopUp from '../UserAuth/UserPopUp';
import FeedbackPopUp from '../Feedback/FeedbackPopUp';
import { Auth, Hub } from 'aws-amplify';

function CreateGroupButton() {
    const navigate = useNavigate();

    function handleClick() {
        navigate('create-group')
    }

    return (
        <button className="homeButton" onClick={handleClick}>
            <p>Create Group</p>
        </button>
    );
}

function JoinGroupSection(props) {
    const [showGoToGroupBox, setShowGoToGroupBox] = useState(false);
    const [groupID, setGroupID] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const navigate = useNavigate();

    function handleClick() {
        setShowGoToGroupBox(true);
    }

    function handleGroupIDChange(event) {
        setGroupID(event.target.value);
    }

    function handleJoin(){
        navigate('/group/'+ groupID);
    }

    return (
        <>
            <button className="homeButton" onClick={handleClick}>
                <p>Join Group</p>
            </button>
            {showGoToGroupBox && (
                <div className="goToGroupBox">
                    <div className="goToGroupBox-content">
                        <div className="goToGroupBox-content-bottom">
                            <input style={{width: "57%"}} type="text" placeholder="Group ID" onChange={handleGroupIDChange}/>
                            <button style={{width: "30%"}} onClick={handleJoin}>GO</button>
                        </div>
                        { errorMessage != "" &&
                            <div className="errorMessage">
                                <h3>{errorMessage}</h3>
                            </div>
                        }
                    </div>
                </div>
            )}
        </>
    );
}

function Home({updateLocalStorage, user}) {
    const location = useLocation();
    const isRootUrl = location.pathname === '/';
    const [showAuthPopUp, setShowAuthPopUp] = useState(false); 
    const [showUserPopUp , setShowUserPopUp] = useState(false);
    const [showFeedBackPopUp, setShowFeedBackPopUp] = useState(false);
   
    

    return (
        <div className={`home ${isRootUrl ? 'whiteBackground' : ''}`}>  
            
            <img className="blur" 
                src={require('../assets/blurs/blur1.png')}
                style={{
                    position: "absolute", 
                    bottom: "0%", 
                    left: "0%",
                    zIndex: "0",
                    height: "99%",
                    width: "auto",
                    maxWidth: "100%"
                }}>
            </img>
            <img className="blur"
                src={require('../assets/blurs/blur2.png')}
                style={{
                    position: "absolute",
                    bottom: "0",
                    right: "0",
                    zIndex: "0",
                    height: "99%",
                    width: "auto",
                    maxWidth: "100%"
                    }}>
            </img>


            <div className='homeContent'>
                
                    <img className='mainLogo' src={require('../assets/logoWithText.png')}/>

                <div style={{textAlign: "center", width: "100%"}}>
                    <h2 style={{margin: "0"}}>Restaurant Bill Splitting</h2>
                   
                    {/* <div className='homeSteps'>
                        
                            <p>1. Create Group and Scan Bill</p> 
                            <p>2. Friends Join and Claim Items</p>
                            <p>3. Confirm Selection and Pay</p>
                        
                    </div> */}

                </div>
                <div style={{width: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
                <div className="homeButtonDiv">
                    <CreateGroupButton />
                </div>
                <div className="homeButtonDiv">
                    <JoinGroupSection updateLocalStorage={updateLocalStorage}/>
                </div>
                <div className="homeButtonDivBottom">
                    {user ? (
                        <button className='homeButton2' onClick={() => setShowUserPopUp(true)}><p className='bottomRowText'>Account</p></button>
                    ) : (
                        <button className='homeButton2' onClick={() => setShowAuthPopUp(true)}><p className='bottomRowText'>Sign In</p></button>
                    )}
                    <button className='homeButton2' onClick={() => setShowFeedBackPopUp(true)}><p className='bottomRowText'>Feedback</p></button>
                </div>
                </div>
                {/* <div className="bottomText">
                    {user ? (
                        <p onClick={() => setShowUserPopUp(true)}>Signed in as :  {user.attributes.name.split(" ")[0]}</p>
                    ) : (
                        <button className='authButton' onClick={() => setShowAuthPopUp(true)}>Sign In/Sign Up</button>
                    )}
                </div> */}
            </div>

            {showAuthPopUp &&
                <AuthPopUp setShow={setShowAuthPopUp}/>
            }
            {showUserPopUp &&
                <UserPopUp setShow={setShowUserPopUp} userIn={user}/>
            }
            {showFeedBackPopUp &&
                <FeedbackPopUp set={setShowFeedBackPopUp}/>
            }
        </div>
    );
}

export default Home;
