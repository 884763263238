import "./style.css";
import React, { useEffect, useState } from 'react';
import UserInfo from "./UserInfo/UserInfo";





export default function OwedToYou({setShow, users, items, getTotalFunc, birthdayUsers}) {
  const [visible, setVisible] = useState(false);

  const [selectedUserIndex, setSelectedUserIndex] = useState(-1);

  let usersFiltered = []

  for (let user of Object.keys(users)) {
    if (user !== localStorage.getItem("username") && !birthdayUsers.includes(user)) {
        usersFiltered.push(user);
    }
  }

  useEffect(() => {
    setVisible(true);
    return () => {
      setVisible(false);
    };
  }, []);

  return (
    <>
      <div className="owed-to-you-container">

        <img className="blur"
          src={require('../../assets/blurs/blur1.png')}
          style={{
            position: "absolute",
            bottom: "0%",
            left: "0%",
            zIndex: "0",
            height: "80%",
            width: "auto"
          }}>
        </img>
        <img className="blur"
          src={require('../../assets/blurs/blur2.png')}
          style={{
            position: "absolute",
            top: "20%",
            right: "0%",
            zIndex: "0",
            height: "100%",
            width: "auto"
          }}>
        </img>

        <div className="owed-to-you-top">
          <h2>Owed To You</h2>
        </div>

        <div className="owed-to-you-mid">
                <ul style={{ listStyle: "none", width: "100%" }}>
                  {usersFiltered.map((user, index) => (
                    <UserInfo
                      key={index}
                      index={index}
                      user={user}
                      users={users}
                      items={items}
                      selectedIndex={selectedUserIndex}
                      setSelectedIndex={setSelectedUserIndex}
                      confirmed={users[user][3]}
                      getTotalFunc={getTotalFunc}
                      birthdayUsers={birthdayUsers}
                    />
                  ))}
                </ul>
        </div>

        <div className="owed-to-you-bottom" >
          <button style={{height: "80%", width: "95%", borderRadius: "50px", fontSize : "200%"}}
            onClick={() => setShow(false)}>
            Go Back
          </button>
        </div>






      </div>
    </>
  );
}
