import React, { useState, useEffect } from 'react';
import './style.css';

export const SendFeedback = async (category, email, body, picture) => {
    const requestBody = {
        category: category,
        email: email,
        body: body,
        picture: picture
    };

    try {
        const response = await fetch(process.env.REACT_APP_API_URL + "/Prod/sendfeedback/", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
        });

        if (response.status === 200) {
            const jsonResponse = await response.json();
            alert('Feedback sent successfully');
        } else {
            console.error('Error sending feedback:', response);
            alert('Error: Something went wrong');
        }
    } catch (error) {
        console.error('Error triggering Lambda function:', error);
        alert('Error: Something went wrong');
    }
};

export default function FeedbackPopUp({ setShow }) {
    const [visible, setVisible] = useState(false);
    const [category, setCategory] = useState('');
    const [email, setEmail] = useState('');
    const [body, setBody] = useState('');
    const [picture, setPicture] = useState(null);

    const placeholderText = {
        "General feedback": "Describe your overall experience or suggestions.",
        "Bug report": "Please describe the issue, what you were trying to do, and any error messages.",
        "Bill scanning": "Describe your issue or feedback about bill scanning, and don't forget to upload an image of the bill."
    };

    useEffect(() => {
        setVisible(true);
        return () => {
            setVisible(false);
        };
    }, []);

    const handleFeedbackSubmit = async (e) => {
        e.preventDefault();
        if (category === 'Bill scanning' && !picture) {
            alert('Please upload an image of the bill for bill scanning feedback.');
            return;
        }
        await SendFeedback(category, email, body, picture);
        setShow(false);
    };

    const handlePictureChange = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            setPicture(reader.result);
        };
        if (file) {
            reader.readAsDataURL(file);
        }
    };

    return (
        <>
            <div className={`disabledBackground ${visible ? 'greyedOutvisible' : ''}`}></div>
            <div className="FeedbackPopUp">
                <div className='top'>
                    <button onClick={() => setShow(false)} style={{width: "25px", height: "25px"}} className='closeButton'>
                        <img className='closeButtonImage' src={require('../assets/close.png')} alt="Close" />
                    </button>
                </div>
                <form onSubmit={handleFeedbackSubmit}>
                    <div>
                        <select value={category} onChange={(e) => setCategory(e.target.value)} required>
                            <option value="">Please Select</option>
                            <option value="General feedback">General Feedback</option>
                            <option value="Bug report">Bug Report</option>
                            <option value="Bill scanning">Bill Scanning</option>
                        </select>
                    </div>
                    <div>
                        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Your email" required />
                    </div>
                    <div>
                        <textarea value={body} onChange={(e) => setBody(e.target.value)} placeholder={placeholderText[category] || "Select a category to see instructions."} required />
                    </div>
                    {category === "Bill scanning" && (
                        <div>
                            <input type="file" onChange={handlePictureChange} accept="image/*" required />
                        </div>
                    )}
                    <div>
                        <button type="submit">Send Feedback</button>
                    </div>
                </form>
            </div>
        </>
    );
}
