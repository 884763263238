import "./style.css";
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { MakeUserAdmin, KickUser } from '../../ApiCalls/Api';
import AnimateHeight from "react-animate-height";
import ClaimerIcon from '../../ClaimerIcon/ClaimerIcon';
import ScaleLoader from "react-spinners/ScaleLoader";
import ListItem from './ItemLists/ListItem';


export default function ClaimMoreItems({
    id,
    items,
    fetchData,   
    users,       
    userName,    
    serviceCharge,  
    splitSCEqually,  
    excludedTax,    
    globalDiscount, 
    setIsWaiting,
    popUpOpen,
    tip
}) {


    const [visible, setVisible] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(-1);

    useEffect(() => {
        console.log("Checking Sc")
        console.log(serviceCharge)
        setVisible(true);
        return () => {
          setVisible(false);
        };
      }, []);

    return (
        <div className={`ClaimeMoreItems-Container ${visible ? 'visible' : ''}`}>
            <div className='ClaimeMoreItemsTitle'>
                <div style={{ width: '10%' }}></div>
                <div
                    style={{
                        width: '80%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <h1>Claim Items</h1>
                </div>
                <div className='closeButtonDiv' style={{ width: '10%' }}>
                    {/* <button onClick={() => popUpOpen(false)} className='closeButton'>
                        <img className='closeButtonImage' src={require('../../../assets/close.png')} />
                    </button> */}
                </div>
            </div>

            <div style={{ marginTop: '10px', width: '85%' }} className='div-ForLine'></div>

                <div className='bill-Items-Content-Section'>
                    <div style={{ overflow: 'scroll', height: '100%' }}>
                        <ul style={{ listStyle: 'none' }}>
                            {items.map((item, index) => (
                                <ListItem
                                    roomID={id}
                                    key={item.id}
                                    item={item}
                                    fetchFunc={fetchData}
                                    users={users}
                                    userName={userName}
                                    ClaimerIcon={ClaimerIcon}
                                    serviceCharge={serviceCharge}
                                    splitSCEqually={splitSCEqually}
                                    excludedTax={excludedTax}
                                    discount={globalDiscount}
                                    index={index}
                                    selectedIndex={selectedIndex}
                                    setSelectedIndex={setSelectedIndex}
                                    setIsWaiting={setIsWaiting}
                                    tip={tip}
                                />
                            ))}
                        </ul>
                    </div>
                </div>


            <div style={{ height: '12%', width: "85%", borderTop: "1px solid grey", paddingTop: "10px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <button style={{ width: "100%", height: "80%", fontSize: "200%", borderRadius: "35px" }} className='shareGroupButton' onClick={() => popUpOpen(false) }>Done</button>
            </div>

        </div>
    );

}