import React, { createContext, useContext, useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';

const AuthContext = createContext(null);
//Need to get the context working later
export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [cognitoUser, setCognitoUser] = useState(null);

  const signIn = async (email) => {
    const user = await Auth.signIn(email);
    setCognitoUser(user);
  };

  const signOut = async () => {
    await Auth.signOut();
    setCognitoUser(null);
  };

  const answerCustomChallenge = async (answer) => {
    const updatedUser = await Auth.sendCustomChallengeAnswer(cognitoUser, answer);
    setCognitoUser(updatedUser);
    return isAuthenticated();
  };

  const getPublicChallengeParameters = () => {
    return cognitoUser?.challengeParam;
  };

  const signUp = async (email, fullName) => {
    const params = {
      username: email,
      password: getRandomString(30),
      attributes: {
        name: fullName,
      },
    };
    await Auth.signUp(params);
  };

  const confirmSignUp = async (email, code) => {
    try {
        await Auth.confirmSignUp(email, code);
    } catch (error) {
        throw error; // Rethrow the error to be handled in the component
    }
    };

  const getRandomString = (bytes) => {
    const randomValues = new Uint8Array(bytes);
    window.crypto.getRandomValues(randomValues);
    return Array.from(randomValues).map(intToHex).join('');
  };

  const intToHex = (nr) => {
    return nr.toString(16).padStart(2, '0');
  };

  const isAuthenticated = async () => {
    try {
      await Auth.currentSession();
      return true;
    } catch {
      return false;
    }
  };

  const getUserDetails = async () => {
    if (!cognitoUser) {
      const user = await Auth.currentAuthenticatedUser();
      setCognitoUser(user);
    }
    return await Auth.userAttributes(cognitoUser);
  };

  // Effect to check authentication state on component mount
  useEffect(() => {
    isAuthenticated().then((isLoggedIn) => {
      if (isLoggedIn) {
        Auth.currentAuthenticatedUser().then(setCognitoUser);
      }
    });
  }, []);

  const value = {
    cognitoUser,
    signIn,
    signOut,
    answerCustomChallenge,
    getPublicChallengeParameters,
    signUp,
    confirmSignUp,
    isAuthenticated,
    getUserDetails,
  };

   return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
