
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import AnimateHeight from "react-animate-height";
import '../style.css';

import VerboseListItem from '../ItemLists/VerboseListItem'


export default function YourTotalDetails({userName, items, total, birthdayUsers, totalGift, discount, serviceCharge, splitSCEqually, excludedTax, users, setShowTotalDetails, serivceChargeTotal, roomID, fetchFunc, setIsWaiting, tip, UnClaimItem }) {
    const [selectedIndex, setSelectedIndex] = useState(-1);

    const claimedItems = () => {
        let claimedItems = [];
        items.forEach(item => {
            if (item.claimees.includes(userName)) {
                claimedItems.push(item);
            }
        });
        return claimedItems;
    }   

    return (
        <div className="your-total-details">
            <div className="your-total-details-title">
                <div style={{width: "10%", display: "flex", justifyContent:"center", alignContent: "center"}}>
                    <button className='backButton' onClick={() => setShowTotalDetails(false)} style={{backgroundColor: "transparent"}}>
                        <img className='backArrowImage' src={require('../../assets/backArrow.png')} alt="Back" />
                    </button>
                </div>
                <div style={{width: "80%", textAlign: "center"}}>
                    <h2>Your Items</h2>
                </div>
                <div style={{width: "10%"}}>

                </div>
                
            </div>
            <div className="your-total-details-list">
                <ul style={{listStyle:"none", width:"100%"}}>
                    {birthdayUsers.includes(userName) && (
                        <li style={{width: "100%"}}>
                            <div className="user-list-bill-item">
                                <div className='user-list-bill-item-horizantal'>
                                    <h2>Happy Birthday!</h2>
                                    <h2>- {total}</h2>
                                </div>
                                <div style={{textAlign: "center", marginBottom: "5px"}}>
                                    <h4>Your total will be split amongst the other group members</h4>
                                </div>
                            </div>
                        </li>
                    )}
                    {claimedItems().map((item, index) => {
                        return(
                            <VerboseListItem item={item} discount={discount} serviceCharge={serviceCharge} excludedTax={excludedTax} splitSCEqually={splitSCEqually} roomID={roomID} fetchFunc={fetchFunc} setIsWaiting={setIsWaiting} index={index} selectedIndex={selectedIndex} setSelectedIndex={setSelectedIndex} tip={tip} UnClaimItem={UnClaimItem}></VerboseListItem>
                        );
                        
                    })}
                    {splitSCEqually && (
                        <li style={{width: "100%"}}>
                            <div className="user-list-bill-item">
                                <div className='user-list-bill-item-horizantal'>
                                    <h3>Service Charge</h3>
                                    <h4>{serivceChargeTotal}</h4>
                                </div>
                                <div className='user-list-bill-item-horizantal'>
                                <p>Service Charge Equally Split Among </p>
                                <p>{Object.keys(users).length}</p>
                                </div>
                                
                                <div className='user-list-bill-item-horizantal'>
                                    <h3>Final Price</h3>
                                    <h2>{(serivceChargeTotal / Object.keys(users).length).toFixed(2)}</h2>
                                </div>
                            </div>
                        </li>
                    )}       
                    {!birthdayUsers.includes(userName) && birthdayUsers.length > 0 && (
                        <li style={{width: "100%"}}>
                            <div className="user-list-bill-item">
                                <div className='user-list-bill-item-horizantal'>
                                    <h2>Birthday Gift</h2>
                                    <h2>{parseFloat(totalGift).toFixed(2)}</h2>
                                </div>
                            </div>
                        </li>
                    )}
                        
                </ul>
            </div>


            <div className="your-total-details-total">
                <div className='user-list-bill-item-horizantal'>
                    <h1>You Pay : </h1>
                    {birthdayUsers.includes(userName) && (
                        <h1> 00.00

                        </h1>
                    )}
                    {!birthdayUsers.includes(userName) && (
                    <h1>{parseFloat(total).toFixed(2)}</h1>
                    )}
                </div>
                    
            </div>

        </div>
    );
}