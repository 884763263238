import React, { useState, useRef, useEffect } from 'react';
import '../../style.css';
import Switch from 'react-switch';
import { ChangeServiceCharge } from '../../ApiCalls/Api';
import ScaleLoader from "react-spinners/ScaleLoader";


export default function SetServiceChargeOptionsBox({ total, serviceCharge, splitEqually, setShowApplied, queryBackend }) {

  const [serviceChargeNew, setServiceChargeNew] = useState(serviceCharge);
  const [newSplitEqually, setNewSplitEqually] = useState(splitEqually);
  const [newServiceCharge, setNewServiceCharge] = useState(serviceCharge);
  const [newServiceChargeAmount, setNewServiceChargeAmount] = useState(formatNumber(total * (serviceCharge / 100)));
  const [dontsplitServiceChargeEqually, setDontSplitServiceChargeEqually] = useState(true);


  const [isWaiting, setIsWaiting] = useState(false);

  // function handleChangeEqually() {
  //   if (dontsplitServiceChargeEqually) {
  //     window.confirm("Are you sure you want to split the service charge equally? This means that all group members will pay the same share of the service charge, regardless of what they ordered rather than paying service charge as a percentage of the items they had claimed.")
  //     setDontSplitServiceChargeEqually(false)
  //   } else {
  //     setDontSplitServiceChargeEqually(true)
  //   }

  // }

  function formatNumber(n) {
    if (Math.floor(n) === n) return n.toString();
    if ((n * 10) % 1 === 0) return n.toFixed(1);
    return n.toFixed(2);
  }



  async function handleSetServiceCharge(serviceChargeNew, newSplitEqually) {
    if (window.confirm("Are you sure you want to change the Service Charge to " + serviceChargeNew + "% ?")) {
      setIsWaiting(true);
      await ChangeServiceCharge(serviceChargeNew, newSplitEqually);
      await queryBackend();
      setIsWaiting(false);
      setShowApplied(true);
    }
  }

  function changeServiceChargePercentage(input) {
    setNewServiceCharge(input);
    setNewServiceChargeAmount(formatNumber(total * (input / 100)));
  }

  function changeServiceChargeAmount(input) {
    setNewServiceChargeAmount(input);
    setNewServiceCharge(formatNumber(input / total * 100));
  }

  return (
    <div className='OptionsBox' >
      <div className='tascInputsContainer' style={{ height: "auto" }}>
        <div className='tascInputContainer' style={{ width: "100%" }}>
          <div className='tascInputFeild'>
            <h2>Service Charge Percentage  </h2>
            <input type='number' pattern="\d*(\.\d*)?" min="0" max="100"
              style={{ width: "34%", textAlign: "right" }}
              value={newServiceCharge}
              inputMode='decimal'
              onChange={event => changeServiceChargePercentage(event.target.value)}
              onKeyPress={(event) => {
                const regex = /^(\d*\.?\d{0,2}|\d+)$/;
                const value = event.target.value + event.key;
                if (!regex.test(value) || Number(value) > 99) {
                  event.preventDefault();
                }
              }}
            />
          </div>

        </div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'spaceBetween', width: '80%' }}>
          <hr style={{ width: '40%', borderColor: 'grey', borderWidth: '1px', borderTop: "none" }} />
          <p>OR</p>
          <hr style={{ width: '40%', borderColor: 'grey', borderWidth: '1px', borderTop: "none" }} />
        </div>
        <div className='tascInputContainer' style={{ width: "100%" }}>
          <div className='tascInputFeild'>
            <h2>Service Charge Amount  </h2>
            <input type='number' pattern="\d*(\.\d*)?" min="0" 
              style={{ width: "34%", textAlign: "right" }}
              value={newServiceChargeAmount}
              inputMode='decimal'
              onChange={event => changeServiceChargeAmount(event.target.value)}
              onKeyPress={(event) => {
                const regex = /^(\d*\.?\d{0,2}|\d+)$/;
                const value = event.target.value + event.key;
                if (!regex.test(value) || Number(value) < 0) {
                  event.preventDefault();
                }
              }}
            />
          </div>

        </div>
        {/* <hr style={{ width: '80%', borderTop: "none", borderColor: 'grey', borderWidth: '0.5px', marginTop: "12px" }} />
        <div className='tascInputContainer' style={{ width: "100%" }}>
          <div className='tascInputFeild'>
            <h3>Split Based on Items Claimed</h3>
            <Switch onChange={handleChangeEqually} checked={dontsplitServiceChargeEqually} />
          </div>
        </div> */}
      </div>
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <button
          style={{
            display: "flex",
            justifyContent: 'center',
            alignItems: "center",
            width: "100%"
          }}
          onClick={() => handleSetServiceCharge(newServiceCharge, !dontsplitServiceChargeEqually)}
        >
          {isWaiting ? <ScaleLoader color="white" height="19px" /> : <>Apply</>}
        </button>
      </div>

    </div>
  )
}