import "../../style.css";
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import ScaleLoader from "react-spinners/ScaleLoader";
import { AddItem } from '../../ApiCalls/Api';

export default function AddItems({ roomId, queryBackend, setShowChangeApplied}) {
    const [isWaiting, setIsWaiting] = useState(false);
    const [provisionalItemName, setProvisionalItemName] = useState("");
    const [provisionalItemTotalPrice, setProvisionalItemTotalPrice] = useState("");

    async function handleAddNewItem() {
        if (window.confirm("Are you sure you want to add an item named \"" + provisionalItemName + "\" with a price of " + provisionalItemTotalPrice)) {
            setIsWaiting(true);
            await AddItem(roomId, provisionalItemName, provisionalItemTotalPrice);
            await queryBackend();
            setProvisionalItemName("");
            setProvisionalItemTotalPrice("");
            setIsWaiting(false);
            setShowChangeApplied(true);
        }
    }

    const isButtonDisabled = !provisionalItemName || !provisionalItemTotalPrice || isNaN(provisionalItemTotalPrice);

    return (
        <>
            <div className="addItems">
                <div className="addItemsTop">
                    <input
                        type="text"
                        style={{ width: "100%" }}
                        placeholder="Item Name"
                        value={provisionalItemName}
                        onChange={(e) => setProvisionalItemName(e.target.value)}
                    />
                </div>
                <div className="addItemsMid">
                    <input
                        type='number' pattern="\d*(\.\d*)?"
                        inputMode='decimal'
                        style={{ width: "40%" }}
                        placeholder="Price"
                        value={provisionalItemTotalPrice}
                        onChange={(e) => setProvisionalItemTotalPrice(e.target.value)}
                    />

                    <button
                        style={{
                            display: "flex",
                            justifyContent: 'center',
                            alignItems: "center",
                            width: "50%",
                            opacity: isButtonDisabled ? 0.5 : 1
                        }}
                        onClick={handleAddNewItem}
                        disabled={isButtonDisabled}
                    >
                        {isWaiting ? <ScaleLoader color="white" height="19px" /> : <>Submit</>}
                    </button>
                </div>


            </div>
        </>
    );
}
