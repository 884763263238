import React, { useState, useEffect } from 'react';
import '../index.css'; // Make sure to import your CSS

export default function ChangeApplied({ setShow }) {
    const [fade, setFade] = useState('fade-in');

    useEffect(() => {
        // After the component is mounted, wait for 2 seconds
        const timeout1 = setTimeout(() => {
            setFade('fade-out');
        }, 2000);

        // Wait for the fade-out animation to complete before setting the setShow to false
        const timeout2 = setTimeout(() => {
            setShow(false);
        }, 2500); // 2000 + 500 (duration of fade-out animation)

        // Cleanup timeouts on component unmount
        return () => {
            clearTimeout(timeout1);
            clearTimeout(timeout2);
        };
    }, [setShow]);

    return (
        <>
            <div className={`changeApplied ${fade}`} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <div className="changeAppliedText">
                    <h2 style={{ color: "green" }}>Change Applied</h2>
                </div>
            </div>
        </>
    );
}
