import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';

import { Amplify } from 'aws-amplify';
import awsconfig from './aws-export';

Amplify.configure(awsconfig);



document.title = "DineDivide";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
            <React.StrictMode>
                <div className="main">
                    <App />
                </div>
            </React.StrictMode>
            );




