import React, { useState, useRef, useEffect } from 'react';
import '../../style.css';
import Switch from 'react-switch';
import { ChangeTip } from '../../ApiCalls/Api';
import ScaleLoader from "react-spinners/ScaleLoader";


export default function SetTipOptionsBox({ total, tip, setShowApplied, queryBackend }) {

  const [newtip, setNewtip] = useState(tip);
  const [newtipAmount, setNewtipAmount] = useState(formatNumber(total * (tip / 100)));
  const [dontsplittipEqually, setDontSplittipEqually] = useState(true);


  const [isWaiting, setIsWaiting] = useState(false);

  function formatNumber(n) {
    if (Math.floor(n) === n) return n.toString();
    if ((n * 10) % 1 === 0) return n.toFixed(1);
    return n.toFixed(2);
  }

  async function handleSettip(newTip) {
    if (window.confirm("Are you sure you want to change the Service Charge to " + newTip + "% ?")) {
      setIsWaiting(true);
      await ChangeTip(newTip);
      await queryBackend();
      setIsWaiting(false);
      setShowApplied(true);
    }
  }

  function changetipPercentage(input) {
    setNewtip(input);
    setNewtipAmount(formatNumber(total * (input / 100)));
  }

  function changetipAmount(input) {
    setNewtipAmount(input);
    setNewtip(formatNumber(input / total * 100));
  }

  return (
    <div className='OptionsBox' >
      <div className='tascInputsContainer' style={{ height: "auto" }}>
        <div className='tascInputContainer' style={{ width: "100%" }}>
          <div className='tascInputFeild'>
            <h2>Tip Percentage  </h2>
            <input type='number' pattern="\d*(\.\d*)?" min="0" max="100"
              style={{ width: "34%", textAlign: "right" }}
              value={newtip}
              inputMode='decimal'
              onChange={event => changetipPercentage(event.target.value)}
              onKeyPress={(event) => {
                const regex = /^(\d*\.?\d{0,2}|\d+)$/;
                const value = event.target.value + event.key;
                if (!regex.test(value) || Number(value) > 99) {
                  event.preventDefault();
                }
              }}
            />
          </div>

        </div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'spaceBetween', width: '80%' }}>
          <hr style={{ width: '40%', borderColor: 'grey', borderWidth: '1px', borderTop: "none" }} />
          <p>OR</p>
          <hr style={{ width: '40%', borderColor: 'grey', borderWidth: '1px', borderTop: "none" }} />
        </div>
        <div className='tascInputContainer' style={{ width: "100%" }}>
          <div className='tascInputFeild'>
            <h2>Tip Amount  </h2>
            <input type='number' pattern="\d*(\.\d*)?" min="0" 
              style={{ width: "34%", textAlign: "right" }}
              value={newtipAmount}
              inputMode='decimal'
              onChange={event => changetipAmount(event.target.value)}
              onKeyPress={(event) => {
                const regex = /^(\d*\.?\d{0,2}|\d+)$/;
                const value = event.target.value + event.key;
                if (!regex.test(value) || Number(value) < 0) {
                  event.preventDefault();
                }
              }}
            />
          </div>

        </div>
      </div>
      <div style={{ marginTop: "20px", width: "100%", display: "flex", justifyContent: "center" }}>
        <button
          style={{
            display: "flex",
            justifyContent: 'center',
            alignItems: "center",
            width: "100%"
          }}
          onClick={() => handleSettip(newtip, !dontsplittipEqually)}
        >
          {isWaiting ? <ScaleLoader color="white" height="19px" /> : <>Apply</>}
        </button>
      </div>

    </div>
  )
}