import React, { useState } from 'react';
import { ScaleLoader } from "react-spinners";

import { useAuth } from './auth'; // Import useAuth from your AuthProvider

function SignIn({ setShow }) {
    const { signIn, answerCustomChallenge } = useAuth(); // Destructure methods from useAuth
    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState(''); // OTP state
    const [errorMessage, setErrorMessage] = useState('');
    const [isWaiting, setIsWaiting] = useState(false);
    const [isOtpRequired, setIsOtpRequired] = useState(false); // State to track if OTP is required

    const handleSignIn = async () => {
        try {
            setIsWaiting(true);
            await signIn(email); // Use the signIn method from useAuth
            setIsOtpRequired(true); // Prompt user for OTP
            setIsWaiting(false);
        } catch (error) {
            setErrorMessage(error.message);
            console.error('Error signing in', error);
            setIsWaiting(false);
        }
    };

    const handleOtpSubmit = async () => {
        try {
            setIsWaiting(true);
            const isAuthenticated = await answerCustomChallenge(otp); // Use the answerCustomChallenge method from useAuth
            if (isAuthenticated) {
                await alert('Successfully signed in!');
                setShow(false);
            } else {
                setErrorMessage('Invalid OTP. Please try again.');
            }
            setIsWaiting(false);
        } catch (error) {
            setErrorMessage(error.message);
            console.error('Error submitting OTP', error);
            setIsWaiting(false);
        }
    };

    return (
        <div className="inputs">
            {isOtpRequired ? (
                <>
                    <h2>Enter Code in Email</h2>
                    <input
                        value={otp}
                        onChange={e => setOtp(e.target.value)}
                        placeholder="One Time Password"
                    />
                    <button 
                        onClick={handleOtpSubmit} 
                        disabled={otp.length === 0 || isWaiting}>
                        {isWaiting ? <ScaleLoader color="white" height="18px"/> : 'Submit'}
                    </button>
                    {errorMessage && <p style={{color: 'red'}}>{errorMessage}</p>}
                </>
            ) : (
                <>
                    {/* Commenting out third-party auth for now
                    <button className="identity-button">Sign In With Google</button>
                    <button className="identity-button">Sign In With Facebook</button>
                    <button className="identity-button">Sign In With Apple</button>
                    */}
                    <input
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        placeholder="Email"
                    />
                    <button 
                        onClick={handleSignIn} 
                        disabled={email.length === 0 || isWaiting}>
                        {isWaiting ? <ScaleLoader color="white" height="18px"/> : 'Send Code To Email'}
                    </button>
                    {errorMessage && <p style={{color: 'red'}}>{errorMessage}</p>}
                </>
            )}
        </div>
    );
}

export default SignIn;
