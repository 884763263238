import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import './style.css';


export default function UserPopUp({setShow, userIn}) {
    const [visible, setVisible] = useState(false);
    const [user, setUser] = useState(userIn);  

    useEffect(() => {
        setVisible(true);
        return () => {
            setVisible(false);
        };
 
    }, []);

    const loadCurrentUser = async () => {
        try {
            const userInfo = await Auth.currentAuthenticatedUser();
            setUser(userInfo);
        } catch (err) {
            console.log("User not logged in")
            console.log(err);
            setUser(null);
        }
    };

    const handleLogOut = async () => {
        try {
            await Auth.signOut();
            setUser(null);
            setShow(false);
        } catch (error) {
            console.error('Error signing out: ', error);
        }
    }
    
    const handleChangeName = async (newName) => {
        try {
            const currentUser = await Auth.currentAuthenticatedUser();
            await Auth.updateUserAttributes(currentUser, {
                'name': newName
            });
            loadCurrentUser(); // refresh user data
        } catch (error) {
            console.error('Error updating name: ', error);
        }
    }
    
    const handleChangeEmail = async (newEmail) => {
        try {
            const currentUser = await Auth.currentAuthenticatedUser();
            await Auth.updateUserAttributes(currentUser, {
                'email': newEmail
            });
            loadCurrentUser(); // refresh user data
        } catch (error) {
            console.error('Error updating email: ', error);
        }
    }
    
    const handleChangePhone = async (newPhone) => {
        try {
            const currentUser = await Auth.currentAuthenticatedUser();
            await Auth.updateUserAttributes(currentUser, {
                'phone_number': newPhone
            });
            loadCurrentUser(); // refresh user data
        } catch (error) {
            console.error('Error updating phone: ', error);
        }
    }
    

   

    return (
        <>
            <div className={`disabledBackground ${visible ? 'greyedOutvisible' : ''}`}></div>
            <div className="UserPopUp">
                <div className='top'>
                        <button onClick={() => setShow(false)} style={{width: "25px", height: "25px"}} className='closeButton'>
                            <img className='closeButtonImage' src={require('../assets/close.png')} />
                        </button>
                </div>
                <div className='userPopUpBody' style={{borderTop: "solid 1px grey"}}>
                    <div className='userDetailsDiv'>
                        <p className='userDetailsLabel'>Name </p> 
                        <p className='userDetailsContentText'> {user.attributes.name}</p>     
                    </div>
                    <div className='userDetailsDiv'>
                        <p className='userDetailsLabel'>Email </p> 
                        { user.attributes.email ?
                            <p className='userDetailsContentText'> {user.attributes.email}</p>
                            :
                            <p className='userDetailsContentText' style={{color: "red"}}> Not Set</p>
                        }
                    </div>
                    <div className='userDetailsDiv'>
                        <p className='userDetailsLabel'>Password </p> 
                        <p className='userDetailsContentText'> ***********</p>
                    </div>
                    
                    <button onClick={handleLogOut} style={{marginTop: "20px"}}>Log Out</button>
                </div>                    

            </div>

        </>
    )
}


