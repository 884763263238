import React, { useState, useRef, useEffect, useMemo } from 'react';
import '../style.css';
import Switch from 'react-switch';
import { useNavigate } from 'react-router-dom';
import UserInfo from './UserInfo/UserInfo';
import EditItems from './EditItems/EditItems';
import AddItems from './AddItems/AddItems';
import SetServiceChargeOptionsBox from './ChangeServiceCharge/ChangeServiceCharge';
import SetTaxOptionsBox from './ChangeTax/ChangeTax';
import SetTipOptionsBox from './ChangeTip/ChangeTip';
import BirthdayUsers from './BirthdayUsers/BirthdayUsers';
import ChangeApplied from "../../utils/ChangeApplied";
import { RemoveUser, ChangeDiscount } from '../ApiCalls/Api';
import ScaleLoader from "react-spinners/ScaleLoader";



export default function GroupOptionsMenu({ roomID, handleCloseOptions, users, items, userName, discount, serviceCharge, splitSCEqually, tax, birthdayUsers, queryBackend, total, showConfettiOption, disableConffeti, setDisableConfetti, tip }) {
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [selectedEditIndex, setSelectedEditIndex] = useState(-1);
    const [visible, setVisible] = useState(false);
    const [userSelectedIndex, setUserSelectedIndex] = useState(-1);
    const [showApplied, setShowApplied] = useState(false);

    const [isWaitingDiscount, setIsWaitingDiscount] = useState(false);
    const [discountNew, setDiscountNew] = useState(discount);

    const navigate = useNavigate();

    useEffect(() => {
        setVisible(true);
        return () => {
            setVisible(false);
        };
    }, []);


    const isCurrentUserAdmin = useMemo(() => {
        return users[userName][2];
    }, [users, userName]);

    const handleOptionClick = (index) => {
        if(selectedIndex != index){
            setSelectedIndex(index);
        }
        
    };

    const deselectOption = (index) => {
        console.log("test")
        console.log(selectedIndex)
        console.log(index)
        if (selectedIndex == index){
            setSelectedIndex(-1);
        }
    };

    const deselectEditOption = (index) => {
        if (selectedEditIndex == index){
            setSelectedEditIndex(-1);
        }
    };


    async function handleSetDiscount() {
        if (window.confirm("Are you sure you want to change the discount to " + discountNew + "% ? This will be applied to all items")) {
            setIsWaitingDiscount(true);
            await ChangeDiscount(discountNew);
            await queryBackend();
            setIsWaitingDiscount(false);
            setShowApplied(true);
        }
    }

    const [tempPassword, setTempPassword] = useState("");



    async function handleApplyPassword(password) {
        const requestBody = {
            roomID: window.location.href.substr(window.location.href.length - 6),
            accessToken: localStorage.getItem("AccessToken"),
            userName: localStorage.getItem("username"),
            newPassword: password,
        };

        try {
            const response = await fetch(process.env.REACT_APP_API_URL + "/Prod/changePassword", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            });

            if (response.status == 200) {
                const jsonResponse = await response.json();
                //console.log('Successfully Discount', jsonResponse);
            } else {
                console.error('Error Chaging Discount:', response);
            }
        } catch (error) {
            console.error('Error triggering Lambda function:', error);
        }
    }


    async function handleLeaveGroup() {
        if (window.confirm("Are you sure you want to leave the group?")) {
            await RemoveUser(roomID, localStorage.getItem("username"), navigate);

            // Remove the localStorage variables
            localStorage.removeItem('AccessToken');
            localStorage.removeItem('groupID');
            localStorage.removeItem('username');
            localStorage.removeItem('password');
        }
    }

    const listItemRefs = useRef([]);

    let listOfEditingOptions = []

    listOfEditingOptions.push(<>
        <h3  onClick={() => deselectEditOption(0)}>Add Items</h3>
        {selectedEditIndex === 0 &&
            <>
                {isCurrentUserAdmin &&
                    <AddItems roomId={roomID} queryBackend={queryBackend} setShowChangeApplied={setShowApplied} />
                }
                {!isCurrentUserAdmin &&
                    <div style={{ display: "flex", width: "80%", justifyContent: 'center', alignContent: 'center', flexDirection: 'column', textAlign: "center" }}>
                        <h4 style={{ color: "red", marginTop: "0px" }}>You must be an admin to add items</h4>
                    </div>
                }
            </>

        }
    </>
    )
    listOfEditingOptions.push(<>
        <div style={{
            // height: selectedIndex === 1 && isCurrentUserAdmin ? "15%" : "50%",
            borderBottom: selectedEditIndex === 1 && isCurrentUserAdmin ? "1px solid grey" : "none",
            width: "95%",
            textAlign: "center",
            minHeight: "60px"
            
        }}
        onClick={() => deselectEditOption(1)}
        >
            <h3>Edit Items</h3>
        </div>
        {selectedEditIndex === 1 &&
            <>
                {isCurrentUserAdmin &&
                    <EditItems roomId={roomID} items={items} queryBackend={queryBackend} />
                }
                {!isCurrentUserAdmin &&
                    <div style={{ display: "flex", width: "80%", justifyContent: 'center', alignContent: 'center', flexDirection: 'column', textAlign: "center" }}>
                        <h4 style={{ color: "red", marginTop: "0px" }}>You must be an admin to edit items</h4>
                    </div>
                }
            </>
        }
    </>
    )
    listOfEditingOptions.push(<>
        <h3 onClick={() => deselectEditOption(2)}>Tip</h3>
        {selectedEditIndex === 2 &&
            <>
                {isCurrentUserAdmin &&
                    <SetTipOptionsBox total={total} tip={tip} setShowApplied={setShowApplied} queryBackend={queryBackend} />
                }
                {!isCurrentUserAdmin &&
                    <div style={{ display: "flex", width: "80%", justifyContent: 'center', alignContent: 'center', flexDirection: 'column', textAlign: "center" }}>
                        <h4 style={{ color: "red", marginTop: "0px" }}>You must be an admin to change the tip</h4>
                    </div>
                }
            </>
        }
    </>
    )
    listOfEditingOptions.push(<>
        <h3 onClick={() => deselectEditOption(2)}>Service Charge</h3>
        {selectedEditIndex === 3 &&
            <>
                {isCurrentUserAdmin &&
                    <SetServiceChargeOptionsBox total={total} serviceCharge={serviceCharge} splitEqually={splitSCEqually} setShowApplied={setShowApplied} queryBackend={queryBackend} />
                }
                {!isCurrentUserAdmin &&
                    <div style={{ display: "flex", width: "80%", justifyContent: 'center', alignContent: 'center', flexDirection: 'column', textAlign: "center" }}>
                        <h4 style={{ color: "red", marginTop: "0px" }}>You must be an admin to change the service charge</h4>
                    </div>
                }
            </>
        }
    </>
    )
    listOfEditingOptions.push(<>
        <h3 onClick={() => deselectEditOption(3)}>Tax</h3>
        {selectedEditIndex === 4 &&
            <>

                {isCurrentUserAdmin &&
                    <SetTaxOptionsBox total={total} tax={tax} setShowApplied={setShowApplied} queryBackend={queryBackend} />
                }
                {!isCurrentUserAdmin &&
                    <div style={{ display: "flex", width: "80%", justifyContent: 'center', alignContent: 'center', flexDirection: 'column', textAlign: "center" }}>
                        <h4 style={{ color: "red", marginTop: "0px" }}>You must be an admin to change the tax</h4>
                    </div>
                }
            </>
        }
    </>
    )
    listOfEditingOptions.push(<>
        <h3 onClick={() => deselectEditOption(4)}>Discount</h3>
        {selectedEditIndex === 5 &&
            <>
                {isCurrentUserAdmin &&
                    <div style={{ display: "flex", width: "100%", justifyContent: 'center', alignContent: 'center', flexDirection: 'column', marginBottom: "10px" }}>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignContent: 'center', marginBottom: "10px" }}>
                            <h3>Discount Percentage </h3>
                            <input style={{ width: "25%" }} type='text' inputMode='decimal' value={discountNew} onChange={(e) => setDiscountNew(e.target.value)} />
                        </div>
                        <button
                            style={{
                                display: "flex",
                                justifyContent: 'center',
                                alignItems: "center",
                                width: "100%"
                            }}
                            onClick={() => handleSetDiscount()}
                        >
                            {isWaitingDiscount ? <ScaleLoader color="white" height="19px" /> : <>Apply</>}
                        </button>
                    </div>
                }
                {!isCurrentUserAdmin &&
                    <div style={{ display: "flex", width: "80%", justifyContent: 'center', alignContent: 'center', flexDirection: 'column', textAlign: "center" }}>
                        <h4 style={{ color: "red", marginTop: "0px" }}>You must be an admin to change the discount</h4>
                    </div>
                }
            </>
        }
    </>
    )


    let listOfOptions = []



    //all options
    listOfOptions.push(<>
        <div  onClick={() => deselectOption(0)}>
            <h3 >Group Members</h3>
        </div>

        {selectedIndex === 0 &&
            //Shows list of users in the group, from here an other users can see what items other users have claimed and admins can remove users from the group
            <>
                <div className="user-list" >
                    <ul style={{ listStyleType: 'none', padding: "0", width: "100%", margin: "0" }}>
                        {Object.keys(users).map((user, index) => {
                            return (
                                <UserInfo roomID={roomID} index={index} user={user} users={users} items={items} currentUserName={userName} selectedIndex={userSelectedIndex} setSelectedIndex={setUserSelectedIndex} setShowApplied={setShowApplied} queryBackend={queryBackend} />
                            )
                        })}
                    </ul>
                </div>
            </>

        }
    </>
    )
    listOfOptions.push(<>
        <h3  onClick={() => deselectOption(1)}>Birthday Users</h3>
        {selectedIndex === 1 &&
            <BirthdayUsers birthdayUsers={birthdayUsers} users={users} userName={userName} queryBackend={queryBackend} />
        }
    </>
    )
    listOfOptions.push(<>
        <div style={{
            borderBottom: selectedIndex === 2 && isCurrentUserAdmin ? "1px solid grey" : "none",
            width: "100%",
            textAlign: "center",
            minHeight: "60px"
        }}
        onClick={() => deselectOption(2)}
        >
        <h3>Edit</h3>
        </div>
        {selectedIndex === 2 &&
            <>
                
                <div style={{width: "90%", overflow: "scroll" }}>
                    <ul >
                        {listOfEditingOptions.map((optionE, indexE) => {
                            return (
                                <li
                                    className='option'
                                    onClick={() => {
                                        if(selectedEditIndex != indexE){
                                            setSelectedEditIndex(indexE);
                                        }
                                    }}

                                >
                                    {optionE}
                                </li>
                            )
                        })}
                    </ul>
                </div>
                

            </>
        }
    </>
    )
    if (showConfettiOption) {
        listOfOptions.push(<>
            <h3>Disable Confetti</h3>
            {selectedIndex === 3 &&
                <>
                    <div className='leave-room'>
                        <Switch onChange={() => { setDisableConfetti(!disableConffeti) }} checked={disableConffeti} />
                    </div>
                </>

            }
        </>
        )
    }







    return (
        <div className={`optionsGroup ${visible ? 'visible' : ''}`}>
            <div className='optionsTitle'>
                <div style={{ width: '10%' }}></div>
                <div
                    style={{ width: '80%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                    <h1>Options</h1>
                </div>
                <div className='closeButtonDiv' style={{ width: '10%' }}>
                    <button onClick={() => handleCloseOptions(false)} className='closeButton'>
                        <img className='closeButtonImage' src={require('../../assets/close.png')} />
                    </button>
                </div>
            </div>
            <div style={{ marginTop: '10px', width: '80%' }} className='div-ForLine'></div>
            <div className='optionsContent'>
                <ul style={{ height: "100%" }}>
                    {listOfOptions.map((option, index) => {
                        return (
                            <li
                                className='option'
                                onClick={() => {
                                    handleOptionClick(index);
                                    listItemRefs.current[index].scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" });
                                }}
                                ref={(el) => listItemRefs.current[index] = el}
                            >
                                {option}
                            </li>
                        )
                    })}
                </ul>

            </div>
            <div style={{width: "80%", borderTop: "solid 1px grey"}} onClick={() => {handleOptionClick(10);}}>
                    <h3 style={{ color: "red", textAlign: "center" }}>Leave Group</h3>
                    {selectedIndex === 10 &&
                    <>
                        <div className='leave-room'>
                            <h3>Are you sure you want to leave the group?</h3>
                            <button style={{ width: "90%" }} onClick={() => handleLeaveGroup()}>Leave</button>
                        </div>
                    </>

                        }
            </div>
            {showApplied &&
                <ChangeApplied setShow={setShowApplied} />
            }

        </div>
    );
}