import "./style.css";
import React, { useEffect, useState } from 'react';
import { SelectionConfirm, SelectionUnConfirm } from '../ApiCalls/Api'
import VerboseListItem from '../ItemLists/VerboseListItem'
import UserInfo from "./UserInfo/UserInfo";
import ClaimMoreItems from "./ClaimMoreItems/ClaimMoreItems";
import ScaleLoader from "react-spinners/ScaleLoader";


export default function ConfirmSelection({ userName, items, total, birthdayUsers, totalGift, discount, serviceCharge, splitSCEqually, excludedTax, users, setShowTotalDetails, serivceChargeTotal, roomID, fetchFunc, setIsWaiting, userConfirmed, getTotalFunc, globalDiscount, id, setShowClosedNotif, tip }) {
  const [visible, setVisible] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const [selectedUserIndex, setSelectedUserIndex] = useState(-1);

  const [needsToClaimMore, setNeedsToClaimMore] = useState(false)

  const [isWaitingConfirm, setIsWaitingConfirm] = useState(false);


  useEffect(() => {
    setVisible(true);
    return () => {
      console.log("Unmounted")
      setVisible(false);
      setShowClosedNotif(true);
    };
  }, []);

  const claimedItems = () => {
    let claimedItems = [];
    items.forEach(item => {
      if (item.claimees.includes(userName)) {
        claimedItems.push(item);
      }
    });
    return claimedItems;
  }

  async function handleConfirm() {
    const confirmed = window.confirm("Are you sure you want to confirm? Once all users have confirmed you will not be able change your selection and will have to pay " + total);
    //confirm
    if (confirmed) {
      //confirm selection
      setIsWaitingConfirm(true);
      await SelectionConfirm(roomID);
      await fetchFunc();
      setIsWaitingConfirm(false);
    }
  }

  async function handleUnConfirm() {
    const confirmed = window.confirm("Are you sure you want to remove your confirmation?");
    //confirm
    if (confirmed) { 
      //confirm selection 
      setIsWaitingConfirm(true);
      await SelectionUnConfirm(roomID);
      await fetchFunc();
      setIsWaitingConfirm(false);
    }
  }


  return (
    <>
      {needsToClaimMore &&  !userConfirmed &&
        <ClaimMoreItems id={id} 
                        items={items} 
                        fetchData={fetchFunc} 
                        users={users} 
                        userName={userName} 
                        serviceCharge={serviceCharge} 
                        splitSCEqually={splitSCEqually} 
                        excludedTax={excludedTax} 
                        globalDiscount={globalDiscount}   
                        setIsWaiting={setIsWaiting} 
                        popUpOpen={setNeedsToClaimMore}
                        tip={tip}
      />
    
      }
      <div className={`disabledBackground ${visible ? 'greyedOutvisible' : ''}`}></div>

      <div className="confirm-selection-container">
        {/* Miscellneous back ground blurs astheics */}
        <img className="blur"
          src={require('../../assets/blurs/blur9.png')}
          style={{
            position: "absolute",
            top: "10%",
            left: "0%",
            zIndex: "0",
            height: "80%",
            width: "auto"
          }}>
        </img>
        <img className="blur"
          src={require('../../assets/blurs/blur2.png')}
          style={{
            position: "absolute",
            top: "20%",
            right: "0%",
            zIndex: "0",
            height: "100%",
            width: "auto"
          }}>
        </img>
        {!userConfirmed ?
          (
            <>
              <div className="confirm-selection-header-title" >
                <h2>All Items Claimed</h2>
                <h3>Confirm Your Selection</h3>
                <p>(Tap to Unclaim)</p>
              </div>
              <div className="confirm-your-total-details-list" >
                <ul style={{ listStyle: "none", width: "100%" }}>
                  {birthdayUsers.includes(userName) && (
                    <li style={{ width: "100%" }}>
                      <div className="user-list-bill-item">
                        <div className='user-list-bill-item-horizantal'>
                          <h2>Happy Birthday!</h2>
                          <h2>-{total}</h2>
                        </div>
                        <div style={{ marginBottom: "5px" }}>
                          <h4>Your friends are paying for your meal</h4>
                        </div>
                      </div>
                    </li>
                  )}
                  {claimedItems().map((item, index) => {
                    return (
                      <VerboseListItem item={item} discount={discount} serviceCharge={serviceCharge} excludedTax={excludedTax} splitSCEqually={splitSCEqually} roomID={roomID} fetchFunc={fetchFunc} setIsWaiting={setIsWaiting} index={index} selectedIndex={selectedIndex} setSelectedIndex={setSelectedIndex} tip={tip}></VerboseListItem>
                    );

                  })}
                  {splitSCEqually && (
                    <li style={{ width: "100%" }}>
                      <div className="user-list-bill-item">
                        <div className='user-list-bill-item-horizantal'>
                          <h3>Service Charge</h3>
                          <h4>{serivceChargeTotal}</h4>
                        </div>
                        <div className='user-list-bill-item-horizantal'>
                          <p>Service Charge Equally Split Among </p>
                          <p>{Object.keys(users).length}</p>
                        </div>

                        <div className='user-list-bill-item-horizantal'>
                          <h3>Final Price</h3>
                          <h2>{(serivceChargeTotal / Object.keys(users).length).toFixed(2)}</h2>
                        </div>
                      </div>
                    </li>
                  )}
                  {!birthdayUsers.includes(userName) && birthdayUsers.length > 0 && (
                    <li style={{ width: "100%" }}>
                      <div className="user-list-bill-item">
                        <div className='user-list-bill-item-horizantal'>
                          <h2>Birthday Gift</h2>
                          <h2>{parseFloat(totalGift).toFixed(2)}</h2>
                        </div>
                      </div>
                    </li>
                  )}
                  <li style={{ width: "100%" }}>
                    <div className="confirm-select-more-items">
                      <button onClick={() => setNeedsToClaimMore(true)}>Claim More Items</button>
                    </div>
                  </li>

                </ul>
              </div>
            </>
          )
          :
          (
            <>
              <div className="confirm-selection-header-title" style={{height: "10%"}}>
                <h2>You are Confirmed</h2>
                <p>Waiting for others</p>
              </div>
              <div className="confirm-your-total-details-list" style={{height: "62%"}}>
                <ul style={{ listStyle: "none", width: "100%" }}>
                  {Object.keys(users).map((user, index) => (
                    <UserInfo
                      key={index}
                      index={index}
                      user={user}
                      users={users}
                      items={items}
                      selectedIndex={selectedUserIndex}
                      setSelectedIndex={setSelectedUserIndex}
                      confirmed={users[user][3]}
                      getTotalFunc={getTotalFunc}
                      birthdayUsers={birthdayUsers}
                    />
                  ))}
                </ul>
              </div>

            </>

          )}

        <div className="confirm-your-total-details-total">
          <div className='user-list-bill-item-horizantal'>
            <h1>Pay : </h1>
            {birthdayUsers.includes(userName) && (
              <h1> 00.00

              </h1>
            )}
            {!birthdayUsers.includes(userName) && (
              <h1>{parseFloat(total).toFixed(2)}</h1>
            )}
          </div>

        </div>

        <div className="confirm-your-total-buttons">
          {!userConfirmed ? (
            <button onClick={() => handleConfirm()} style={{ display: "flex", justifyContent: 'center', alignItems: "center" }} disabled={isWaitingConfirm}> 
              {isWaitingConfirm ? <ScaleLoader color="white" height="22px" /> : <>Confirm</>}
            </button>
          ) : (
            <button style={{ fontSize: "120%", display: "flex", justifyContent: 'center', alignItems: "center" }} disabled={isWaitingConfirm} onClick={() => handleUnConfirm()}>
              {isWaitingConfirm ? <ScaleLoader color="white" height="22px" /> : <>Tap here to UnConfirm</>}
            </button>
          )}
        </div>




      </div>
    </>
  );
}
