import "../style.css";
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { MakeUserAdmin, KickUser } from '../../ApiCalls/Api';
import AnimateHeight from "react-animate-height";
import ClaimerIcon from '../../ClaimerIcon/ClaimerIcon';
import ScaleLoader from "react-spinners/ScaleLoader";


export default function UserInfo({ index, user, users, items, selectedIndex, setSelectedIndex, getTotalFunc, birthdayUsers }) {


    const numberOfItemsClaimed = useMemo(() => {
        let count = 0;
        items.forEach(item => {
            if (item.claimees.includes(user)) {
                count++;
            }
        });
        return count;
    }, [items, user]);

    const claimedItems = useMemo(() => {
        let claimedItems = [];
        items.forEach(item => {
            if (item.claimees.includes(user)) {
                claimedItems.push(item);
            }
        });
        return claimedItems;
    }, [items, user]);


    function handleClick() {
        //console.log("Selected index: " + selectedIndex + " index: " + index );
        if (selectedIndex == index) {
            setSelectedIndex(-1);
        } else {
            setSelectedIndex(index);
        }
    }

    const [height, setHeight] = useState("auto");

    useEffect(() => {
        const element = document.getElementById(`user${index}`);
        const width = element.clientWidth;
        const clone = element.cloneNode(true);
        clone.style.width = width + "px";
        clone.style.height = "auto";
        clone.style.position = "absolute";
        clone.style.visibility = "hidden";
        element.parentNode.appendChild(clone);
        const newHeight = clone.clientHeight + 3;
        element.parentNode.removeChild(clone);
        //console.log("New height: " + newHeight);
        setHeight(newHeight);
    }, [selectedIndex, index]);


    return (

        <li className="user-list-item" style={{ borderTop: "none", borderBottom: "solid 1px grey" }}>
            <AnimateHeight className="animatedUserDiv" duration={500} height={height} style={{ width: "100%" }}>
                <div id={"user" + index} style={{ width: "100%" }}>
                    <div className="user-list-item-top">

                        <div className="user-list-item-left">
                            <div onClick={() => handleClick()} className="user-list-icon">
                                <ClaimerIcon users={users} claimee={user} />
                            </div>
                        </div>
                        <div className="user-owes-list-item-right">
                                <h3>{user}</h3>
                                <h2 style={{fontSize: "25px"}}>{getTotalFunc(user)}</h2>
                        </div>
                    </div>
                    {/* <div className="user-list-item-bottom">
                            {selectedIndex == index &&
                                <>
                                    <div style={{width: "100%", display: "flex", justifyContent: "space-between"}}>
                                        <h3 style={{marginLeft: "5px"}}>{birthdayUsers.includes(user) ? "Is paying (Birthday) :" : "Is paying :"}</h3><h3 style={{marginRight: "5px"}}> {birthdayUsers.includes(user) ? "00.00" : getTotalFunc(user)}</h3>
                                    </div>
                                    
                                    <div onClick={() => handleClick()} className="user-list-claimed-items-header">
                                        <p style={{marginLeft: "5px"}}>Items claimed {selectedIndex == index ? ":" : ""}</p>
                                    </div> 
                                    <div className="user-list-claimed-bill-items">
                                        <ul style={{ listStyleType: 'none', padding: "0", width: "100%", margin: "0"}}>
                                            {claimedItems.map((item, index) => {
                                                return (
                                                    <li style={{ width: "100%" }}>
                                                        <div className="user-list-bill-item">
                                                            <div className="user-list-bill-item-horizantal">
                                                                <h4>{item.itemName}</h4>
                                                                <h4>{parseFloat(item.price).toFixed(2)}</h4>
                                                            </div>
                                                        </div>
                                                    </li>
                                                )
                                            })}

                                        </ul>
                                    </div>
                                </>
                            }
                    </div> */}
                    

                </div>
            </AnimateHeight>
        </li>

    );

}