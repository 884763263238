import { useNavigate } from 'react-router-dom';
import { sendMessageThroughWebSocket } from '../Main';



export async function QueryBackend(id, setUserNotInRoom) {

    // //console.log("Querying backend for room information");
    // //console.log("Using the following Username: " + localStorage.getItem("username"));
    // //console.log("Using the following Room ID: " + id);
    // //console.log("Using the following Auth Token: " + localStorage.getItem("AccessToken"));

    const eventBody = {
        username: localStorage.getItem("username"),
        roomID: id,
        accessToken: localStorage.getItem("AccessToken")
    };



    const response = await fetch(process.env.REACT_APP_API_URL + "/Prod/read/", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(eventBody)
    });

    if (response.status !== 200) {
        //console.log('Failed reading from backend');
        if (response.status === 401) {
            //console.log('User not joined room yet');

            setUserNotInRoom(true);
        } else if (response.status === 404) {
            //console.log('Room not found');
            alert("Room no longer exists");
            //TODO: Redirect to room not found page

        } else {
            //console.log('Unknown error');
        }
        return {};
    }


    const responseInJSON = await response.json();
    const output = responseInJSON.Information;
    setUserNotInRoom(false);
    //console.log("Backend Query Complete")
    return output;

}

export async function JoinGroup(id, userName, password, img, showPasswordFunc, updateErrorMsgFunc, updateLocalStorage) {
    if (userName === '') {
        updateErrorMsgFunc('Please enter a username');
    } else {
        //console.log("Querying backend");
        //console.log("Using the following Username: " + userName);
        //console.log("Using the following Room ID: " + id);


        const getIP = async () => {
            return fetch("https://api.ipify.org?format=json")
                .then((response) => response.json())
                .then((data) => data.ip);
        };

        const userIP = await getIP();
        ////console.log("IP : " + userIP);

        const eventBody = {
            userName: userName.trim(),
            roomID: id,
            userIP: userIP,
            password: password,
            userImage: img
        };

        const response = await fetch(process.env.REACT_APP_API_URL + "/Prod/join/", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(eventBody)
        });

        if (response.status !== 200) {
            //console.log('Failed reading from backend');
            if (response.status === 404) {
                //console.log('Group not found');
                updateErrorMsgFunc('Group does not exist, please check the ID and try again');
            } else if (response.status === 401) {
                //console.log('User already exists in group');
                updateErrorMsgFunc('User already exists in group, please use another name');
            } else if (response.status === 201) {
                //console.log('Password Required');
                updateErrorMsgFunc('');
                showPasswordFunc(true);
            } else if (response.status === 402) {
                //console.log('Incorrect Password');
                updateErrorMsgFunc('Incorrect Password');
            } else {
                //console.log('Unknown error');
                updateErrorMsgFunc('Internal Server Error');
            }
        } else {
            const success = await response.json();
            console.log(userName.trim());
            //console.log(success);
            updateLocalStorage(success.AccessToken, id, userName.trim(), password);



            updateErrorMsgFunc('');

        }
    }
}

export const ClaimItem = async (roomID, itemID, currentItemList="noCheck") => {
    //{ "body" : "{ \"password\": \"thisIsThePassword\", \"userIP\": \"1.2.3.4.5.6\", \"userName\": \"testUserName\", \"accessToken\": \"000000\", \"roomID\": \"000000\", \"itemID\": \"00000\" }" }
    const eventBody = {
        password: "",//temporarily hardcoded
        userIP: "1.1.1",//temporarily hardcoded
        userName: localStorage.getItem("username"),
        accessToken: localStorage.getItem("AccessToken"),
        roomID: roomID,
        itemID: itemID,
        currentListOfItems: currentItemList,
    };

    const response = await fetch(process.env.REACT_APP_API_URL + "/Prod/claimItem/", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(eventBody)
    });

    if (response.status !== 200) {
        //console.log('Failed!');
        //console.log(response);
        if (response.status === 404) {
            alert('Item no longer exists');
            //console.log('Item not found');
            //TODO: Redirect to room not found page
        } else {
            alert('Error : Something went wrong');
            //console.log('Unknown error');
        }

    }

    const success = await response.json();
    return success.claimResponse;
    //console.log(success);
}

export const UnClaimItem = async (roomID, itemID) => {
    //{ "body" : "{ \"password\": \"thisIsThePassword\", \"userIP\": \"1.2.3.4.5.6\", \"userName\": \"testUserName\", \"accessToken\": \"000000\", \"roomID\": \"000000\", \"itemID\": \"00000\" }" }

    const eventBody = {
        password: "",//temporarily hardcoded
        userIP: "1.1.1",//temporarily hardcoded
        userName: localStorage.getItem("username"),
        accessToken: localStorage.getItem("AccessToken"),
        roomID: roomID,
        itemID: itemID
    };

    const response = await fetch(process.env.REACT_APP_API_URL + "/Prod/unclaimItem/", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(eventBody)
    });

    if (response.status !== 200) {
        //console.log('Failed!');
        //console.log(response);
        if (response.status === 404) {
            alert('Item no longer exists');
            //console.log('Item not found');
            //TODO: Redirect to room not found page
        } else {
            alert('Error : Something went wrong');
            //console.log('Unknown error');
        }

    }


    const success = await response.json();
    //console.log(success);


}

export const SelectionConfirm = async (roomID) => {
    //{ "body" : "{ \"password\": \"thisIsThePassword\", \"userIP\": \"1.2.3.4.5.6\", \"userName\": \"testUserName\", \"accessToken\": \"000000\", \"roomID\": \"000000\", \"itemID\": \"00000\" }" }

    const eventBody = {
        password: "",//temporarily hardcoded
        userIP: "1.1.1",//temporarily hardcoded
        userName: localStorage.getItem("username"),
        accessToken: localStorage.getItem("AccessToken"),
        roomID: roomID,
        isConfirming: true
    };

    const response = await fetch(process.env.REACT_APP_API_URL + "/Prod/selectionConfirm/", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(eventBody)
    });

    if (response.status !== 200) {
        //console.log('Failed!');
        //console.log(response);
        if (response.status === 404) {
            alert('Error : Something went wrong');
            //console.log('Item not found');
            //TODO: Redirect to room not found page
        } else {
            alert('Error : Something went wrong');
            //console.log('Unknown error');
        }

    }


    const success = await response.json();
    //console.log(success);


}

export const SelectionUnConfirm = async (roomID) => {
    //{ "body" : "{ \"password\": \"thisIsThePassword\", \"userIP\": \"1.2.3.4.5.6\", \"userName\": \"testUserName\", \"accessToken\": \"000000\", \"roomID\": \"000000\", \"itemID\": \"00000\" }" }

    const eventBody = {
        password: "",//temporarily hardcoded
        userIP: "1.1.1",//temporarily hardcoded
        userName: localStorage.getItem("username"),
        accessToken: localStorage.getItem("AccessToken"),
        roomID: roomID,
        isConfirming: false
    };

    const response = await fetch(process.env.REACT_APP_API_URL + "/Prod/selectionConfirm/", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(eventBody)
    });

    if (response.status !== 200) {
        //console.log('Failed!');
        //console.log(response);
        if (response.status === 404) {
            alert('Error : Something went wrong');
            //console.log('Item not found');
            //TODO: Redirect to room not found page
        } else {
            alert('Error : Something went wrong');
            //console.log('Unknown error');
        }

    }


    const success = await response.json();
    //console.log(success);


}

export const MakeUserAdmin = async (roomID, user) => {

    const requestBody = {
        roomID: roomID,
        accessToken: localStorage.getItem("AccessToken"),
        userName: localStorage.getItem("username"),
        targetUser: user,
    };

    //console.log(requestBody)

    try {
        const response = await fetch(process.env.REACT_APP_API_URL + "/Prod/makeUserAdmin/", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
        });

        if (response.status == 200) {
            const jsonResponse = await response.json();
            //console.log('Successfully Chagned Tax', jsonResponse);
        } else {
            console.error('Error Chaging Making User Admin:', response);
            alert('Error : Something went wrong');
        }
    } catch (error) {
        console.error('Error triggering Lambda function:', error);
        alert('Error : Something went wrong');
    }


}

export const RemoveUser = async (roomID, user, navigate) => {
    console.log("Removing User : " + user);

    const requestBody = {
        roomID: roomID,
        accessToken: localStorage.getItem("AccessToken"),
        userName: localStorage.getItem("username"),
        targetUser: user,
    };



    try {
        const response = await fetch(process.env.REACT_APP_API_URL + "/Prod/removeUser/", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
        });

        if (response.status == 200) {
            const jsonResponse = await response.json();
            //console.log('Successfully Chagned Tax', jsonResponse);
            navigate('/');
        } else {
            console.error('Error leaving room:', response);
            alert('Error : Something went wrong');
        }
    } catch (error) {
        console.error('Error triggering Lambda function:', error);
        alert('Error : Something went wrong');

    }


}

export const KickUser = async (roomID, user) => {
    console.log("Removing User : " + user);

    const requestBody = {
        roomID: roomID,
        accessToken: localStorage.getItem("AccessToken"),
        userName: localStorage.getItem("username"),
        targetUser: user,
    };



    try {
        const response = await fetch(process.env.REACT_APP_API_URL + "/Prod/removeUser/", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
        });

        if (response.status == 200) {
            const jsonResponse = await response.json();
            //console.log('Successfully Chagned Tax', jsonResponse);
        } else {
            console.error('Error leaving room:', response);
            alert('Error : Something went wrong');
        }
    } catch (error) {
        console.error('Error triggering Lambda function:', error);
        alert('Error : Something went wrong');

    }


}


export const EditItem = async (roomID, itemID, newName, newPrice) => {
    const requestBody = {
        roomID: roomID,
        accessToken: localStorage.getItem("AccessToken"),
        userName: localStorage.getItem("username"),
        itemID: itemID,
        newName: newName,
        newPrice: newPrice
    };

    try {
        const response = await fetch(process.env.REACT_APP_API_URL + "/Prod/editItem/", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
        });

        if (response.status == 200) {
            const jsonResponse = await response.json();
            //console.log('Successfully Chagned Tax', jsonResponse);
        } else {
            console.error('Error editing item:', response);
            alert('Error : Something went wrong');
        }
    } catch (error) {
        console.error('Error triggering Lambda function:', error);
        alert('Error : Something went wrong');

    }


}

export const DeleteItem = async (roomID, itemID) => {
    const requestBody = {
        roomID: roomID,
        accessToken: localStorage.getItem("AccessToken"),
        userName: localStorage.getItem("username"),
        itemID: itemID,
    };

    try {
        const response = await fetch(process.env.REACT_APP_API_URL + "/Prod/deleteItem/", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
        });

        if (response.status == 200) {
            const jsonResponse = await response.json();
            //console.log('Successfully Chagned Tax', jsonResponse);
        } else {
            console.error('Error editing item:', response);
            alert('Error : Something went wrong');
        }
    } catch (error) {
        console.error('Error triggering Lambda function:', error);
        alert('Error : Something went wrong');

    }


}


export const AddItem = async (roomID, newName, newPrice) => {
    const requestBody = {
        roomID: roomID,
        accessToken: localStorage.getItem("AccessToken"),
        userName: localStorage.getItem("username"),
        newName: newName,
        newPrice: newPrice
    };

    try {
        const response = await fetch(process.env.REACT_APP_API_URL + "/Prod/additem/", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
        });

        if (response.status == 200) {
            const jsonResponse = await response.json();
            //console.log('Successfully Chagned Tax', jsonResponse);
        } else {
            console.error('Error adding item:', response);
            alert('Error : Something went wrong');
        }
    } catch (error) {
        console.error('Error triggering Lambda function:', error);
        alert('Error : Something went wrong');

    }


}

export const RemoveBirthdayUser = async (targetUserName) => {
    const requestBody = {
        roomID: window.location.href.substr(window.location.href.length - 6),
        accessToken: localStorage.getItem("AccessToken"),
        userName: localStorage.getItem("username"),
        targetUser: targetUserName,
    };

    try {
        const response = await fetch(process.env.REACT_APP_API_URL + "/Prod/removeBirthdayUser/", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
        });

        if (response.status == 200) {
            const jsonResponse = await response.json();
            //console.log('Successfully Chagned Tax', jsonResponse);
        } else {
            console.error('Error removing birthday user:', response);
            alert('Error : Something went wrong');
        }
    } catch (error) {
        console.error('Error triggering Lambda function:', error);
        alert('Error : Something went wrong');

    }


}

export const AddBirthdayUser = async (targetUserName) => {
    const requestBody = {
        roomID: window.location.href.substr(window.location.href.length - 6),
        accessToken: localStorage.getItem("AccessToken"),
        requestorUserName: localStorage.getItem("username"),
        targetUserName: targetUserName,
    };

    try {
        const response = await fetch(process.env.REACT_APP_API_URL + "/Prod/addBirthdayUser/", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
        });

        if (response.status == 200) {
            const jsonResponse = await response.json();
            //console.log('Successfully Chagned Tax', jsonResponse);
        } else {
            console.error('Error adding birthday user:', response);
            alert('Error : Something went wrong');
        }
    } catch (error) {
        console.error('Error triggering Lambda function:', error);
        alert('Error : Something went wrong');

    }


}


export const ChangeServiceCharge = async (serviceChargeNew, newSplitEqually) => {
    const requestBody = {
        roomID: window.location.href.substr(window.location.href.length - 6),
        accessToken: localStorage.getItem("AccessToken"),
        userName: localStorage.getItem("username"),
        serviceCharge: serviceChargeNew,
        splitServiceChargeEqually: newSplitEqually,
      };

    try {
        const response = await fetch(process.env.REACT_APP_API_URL + "/Prod/setServiceCharge/", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
        });

        if (response.status == 200) {
            const jsonResponse = await response.json();
            //console.log('Successfully Chagned Tax', jsonResponse);
        } else {
            console.error('Error changing service charge:', response);
            alert('Error : Something went wrong');
        }
    } catch (error) {
        console.error('Error triggering Lambda function:', error);
        alert('Error : Something went wrong');

    }


}

export const ChangeTax = async (tax) => {
    const requestBody = {
        roomID: window.location.href.substr(window.location.href.length - 6),
        accessToken: localStorage.getItem("AccessToken"),
        userName: localStorage.getItem("username"),
        excludedTax: tax
      };

    try {
        const response = await fetch(process.env.REACT_APP_API_URL + "/Prod/setTax/", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
        });

        if (response.status == 200) {
            const jsonResponse = await response.json();
            //console.log('Successfully Chagned Tax', jsonResponse);
        } else {
            console.error('Error changing service charge:', response);
            alert('Error : Something went wrong');
        }
    } catch (error) {
        console.error('Error triggering Lambda function:', error);
        alert('Error : Something went wrong');

    }


}

export const ChangeTip = async (newTip) => {
    const requestBody = {
        roomID: window.location.href.substr(window.location.href.length - 6),
        accessToken: localStorage.getItem("AccessToken"),
        userName: localStorage.getItem("username"),
        newTip: newTip
      };

    try {
        const response = await fetch(process.env.REACT_APP_API_URL + "/Prod/setTip/", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
        });

        if (response.status == 200) {
            const jsonResponse = await response.json();
            //console.log('Successfully Chagned Tax', jsonResponse);
        } else {
            console.error('Error changing service charge:', response);
            alert('Error : Something went wrong');
        }
    } catch (error) {
        console.error('Error triggering Lambda function:', error);
        alert('Error : Something went wrong');

    }


}

export const ChangeDiscount = async (discount) => {
    const requestBody = {
        roomID: window.location.href.substr(window.location.href.length - 6),
        accessToken: localStorage.getItem("AccessToken"),
        userName: localStorage.getItem("username"),
        globalDiscount: discount
      };

    try {
        const response = await fetch(process.env.REACT_APP_API_URL + "/Prod/setDiscount/", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
        });

        if (response.status == 200) {
            const jsonResponse = await response.json();
            //console.log('Successfully Chagned Tax', jsonResponse);
        } else {
            console.error('Error changing service charge:', response);
            alert('Error : Something went wrong');
        }
    } catch (error) {
        console.error('Error triggering Lambda function:', error);
        alert('Error : Something went wrong');

    }


}
