import React, { useState, useRef, useEffect } from 'react';
import '../../style.css';
import Switch from 'react-switch';


export function SetTaxOptionsBox({ setTax, tax, taxAmount, setTaxAmount, setTaxYes, step, goNext }) {


    const [isIncluded, setIsIncluded] = useState((tax == "0" || tax == "") && (taxAmount == "0" || taxAmount == "") );

    function switchFlick(){
        console.log("test")
        if (!isIncluded){
            setTaxYes(true)
            setTax("0")
            setTaxAmount("0")
            if(step == 1){
                goNext()
            }
        }else{
            setTaxYes(false)
        }
    }


    return (
        <div className='OptionBox' >
            <div className='ServiceChargeMiddle'>
                <h3 style={{ margin: "0%", marginBottom: "10px", textAlign: "center" }}>Tax is already included in prices</h3>
                <Switch onChange={() => {setIsIncluded(!isIncluded); switchFlick()}} checked={isIncluded} />
            </div>
            {!isIncluded &&
                <div className='tascInputsContainer' style={{margin: "15px 0px"}}>
                    <div className='tascInputContainer' style={{width: "100%"}}>
                        <div className='tascInputFeild'>
                            <h2 style={{ margin: "0%", marginBottom: "10px", textAlign: "left" }}>Tax Percentage   </h2>
                            <input type="text"
                                style={{ width: "27%", textAlign: "right", backgroundColor: (taxAmount != "" && taxAmount != "0" ) ? 'grey' : 'white', transition: 'background-color 0.5s ease' }}
                                value={tax}
                                inputMode='decimal'
                                onChange={event => setTax(event.target.value)}
                                onKeyPress={(event) => {
                                    const regex = /^(\d*\.?\d{0,2}|\d+)$/;
                                    const value = event.target.value + event.key;
                                    if (!regex.test(value) || Number(value) > 99) {
                                        event.preventDefault();
                                    }
                                }}
                                disabled={(taxAmount != "" && taxAmount != "0" )}
                            />
                        </div>

                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'spaceBetween', width: '80%' }}>
                        <hr style={{ width: '40%', borderColor: 'grey', borderWidth: '1px', borderTop: "none"  }} />
                        <p>OR</p>
                        <hr style={{ width: '40%', borderColor: 'grey', borderWidth: '1px', borderTop: "none"  }} />
                    </div>
                    <div className='tascInputContainer'  style={{width: "100%"}}>
                        <div className='tascInputFeild'>
                            <h2 style={{ margin: "0%", marginBottom: "10px", textAlign: "left" }}>Tax Amount   </h2>
                            <input type="text"
                                style={{ width: "27%", textAlign: "right", backgroundColor: (tax != "" && tax != "0") ? 'grey' : 'white', transition: 'background-color 0.5s ease' }}
                                value={taxAmount}
                                inputMode='decimal'
                                onChange={event => setTaxAmount(event.target.value)}
                                onKeyPress={(event) => {
                                    const regex = /^(\d*\.?\d{0,2}|\d+)$/;
                                    const value = event.target.value + event.key;
                                    if (!regex.test(value) || Number(value) < 0) {
                                        event.preventDefault();
                                    }
                                }}
                                disabled={(tax != "" && tax != "0") }
                            />
                        </div>

                    </div>

                </div>
            }


        </div>
    )
}