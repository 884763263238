import "../../style.css";
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import AnimateHeight from "react-animate-height";
import ClaimerIcon from '../../ClaimerIcon/ClaimerIcon';
import ScaleLoader from "react-spinners/ScaleLoader";
import { AddBirthdayUser, RemoveBirthdayUser } from '../../ApiCalls/Api';

function SelectUserListPopUp({ users, userName, birthdayUsers, setShowSelectUserListPopUp, queryBackend }) {

  const [visible, setVisible] = useState(false);

  const [isWaitingSelectArray, setIsWaitingSelectArray] = useState(new Array(birthdayUsers.length).fill(false));


  useEffect(() => {
    setVisible(true);
    return () => {
      setVisible(false);
    };
  }, []);


  async function handleSelectUser(targetUserName, index) {
    if (window.confirm(`Are you sure you want to make ${targetUserName} a birthday user. Other non birthday users will have to pay ${targetUserName}'s total`)) {

      let newArr = [...isWaitingSelectArray];
      newArr[index] = true;
      setIsWaitingSelectArray(newArr);

      await AddBirthdayUser(targetUserName);
      await queryBackend();

      newArr[index] = false;
      setIsWaitingSelectArray(newArr);
    }

  }

  const nonBirthdayusers = Object.keys(users).filter((user) => !birthdayUsers.includes(user));

  return (
    <div className={`select-user-list-popup ${visible ? 'visible' : ''}`}>
      <div className="select-user-list-popup-header">
        <h3>Select Birthday Users</h3>
      </div>
      <div className="select-user-list-popup-body">
        <ul style={{ listStyle: "none", width: "100%" }}>
          {nonBirthdayusers.map((user, index) => {
            return (
              <li className="select-user-list-popup-user">
                <div className="select-user-list-popup-user-icon">
                  <ClaimerIcon users={users} claimee={user} />
                </div>
                <div className="select-user-list-popup-user-name">
                  <h3>{user}</h3>
                </div>
                <div className="select-user-list-popup-user-button">
                  <button onClick={() => handleSelectUser(user, index)}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "75px"
                    }}>
                    {isWaitingSelectArray[index] ?
                      <ScaleLoader color={"white"} height="18px" /> :
                      <>Select</>
                    }
                  </button>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
      <div className="select-user-list-popup-button-div">
        <button className="select-user-list-popup-button" onClick={() => setShowSelectUserListPopUp(false)}>Done</button>
      </div>
    </div>
  );
}



export default function BirthdayUsers({ birthdayUsers, users, userName, queryBackend }) {

  const [showSelectUserListPopUp, setShowSelectUserListPopUp] = useState(false);
  const [isWaitingDeleteArray, setIsWaitingDeleteArray] = useState(new Array(birthdayUsers.length).fill(false));

  async function handleRemoveUser(user, index) {
    if(window.confirm(`Are you sure you want to remove ${user}?`)){
      let newArr = [...isWaitingDeleteArray];
      newArr[index] = true;
      setIsWaitingDeleteArray(newArr);

      await RemoveBirthdayUser(user);
      await queryBackend();

      newArr[index] = false;
      setIsWaitingDeleteArray(newArr);

    }
  }


  return (
    <>
      <div className="birthday-users">
        <div style={{ width: "100%" }}>
          <p style={{ textAlign: "center", margin: "0" }}>The total claimed by these users will be divided among the others. Ideal for birthday treats.</p>
          {birthdayUsers.length === 0 &&
            <h4 style={{ textAlign: "center"}}>No users selected</h4>
          }
          {birthdayUsers.map((user, index) => {
            return (
              <div className="birthday-user">
                <div className="birthday-user-icon">
                  <ClaimerIcon users={users} claimee={user} />
                </div>
                <div className="birthday-user-name">
                  <h3>{user}</h3>
                </div>
                <div className="birthday-user-button-div">
                  <button
                    onClick={() => handleRemoveUser(user, index)}
                    style={{
                      backgroundColor: "red",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "15px",
                      width: "50px",
                      height: "50px",
                    }}>
                    {isWaitingDeleteArray[index] ?
                      <ScaleLoader color={"white"} height="20px" /> :
                      <img className='provsionalItemsButtons' src={require('../../../assets/bin.png')} />
                    }
                  </button>
                </div>
              </div>
            )
          })}
        </div>
        <div>
          <button className="add-user-button" style={{ marginTop: "10px" }} onClick={() => setShowSelectUserListPopUp(true)}>Select Birthday User</button>
        </div>
      </div>
      {showSelectUserListPopUp &&
        <SelectUserListPopUp users={users}
          userName={userName}
          birthdayUsers={birthdayUsers}
          setShowSelectUserListPopUp={setShowSelectUserListPopUp}
          queryBackend={queryBackend}
        />
      }
    </>
  )
}