import React, { useState, useEffect, useCallback } from 'react';
import '../style.css';


export default function ClaimerIcon({ users ,claimee }){
    const [initials, setInitials] = useState([""]);
    
    useEffect(() => {
        if(claimee.length >= 2) {
            setInitials(claimee.split(' ').map((name) => name[0]).slice(0, 2).join('').toUpperCase());
        }
    });


    //If image is not found then use initials
    return (
      <>
        <div style={{backgroundImage : `url(${users[claimee][0]})`,backgroundSize : "50px" , backgroundColor: `${users[claimee][1]}`}} className='claimeeIconImage'>
              { users[claimee][0] === "NULL" && claimee .split(' ').map((name) => name[0]).slice(0, 2).join('').toUpperCase()}
        </div>
      </>
    );
}