import React, { useState, useEffect, useRef } from 'react';
import '../style.css';

import ListItem from './ListItem';
import ExtraTotalInfo from './ExtraTotalInfo';
import ConfirmCreationBox from './CreationConfirmation';


function SetBillItems({ items, globalDiscount, serviceCharge, excludedTax, serviceChargeAmount, excludedTaxAmount, dontsplitServiceChargeEqually, setIsTakingBillPic, setItems, setShowConfirmCreationBox, renderEditSection, tipAmount, tip, setTipAmount, setTip }) {

    const [selectedIndex, setSelectedIndex] = useState(null);
    const [isEditingCurrentSelected, setIsEditingCurrentSelected] = useState(false);
    const [editItemName, setEditItemName] = useState("");
    const [editItemPrice, setEditItemPrice] = useState("");

    const [isEditingTip, setIsEditingTip] = useState(false);

    const [manualMode, setManualMode] = useState(false);

    const editSectionRef = useRef(null);


    let isExtraInfo = serviceCharge > 0 || excludedTax > 0 || globalDiscount > 0 || serviceChargeAmount > 0 || excludedTaxAmount > 0


    useEffect(() => {

        if ((isNaN(tip) || tip == "") && !isEditingTip) {
            setTip(0);
        }
    }, [tip, isEditingTip]);


    useEffect(() => {
        if ((tip == "") && !isEditingTip) {
            setTip("0.00");
        }

    }, [tip, isEditingTip]);

    useEffect(() => {
        if ((tipAmount == "") && !isEditingTip) {
            setTipAmount("0.00");
        }

    }, [tipAmount, isEditingTip]);

    useEffect(() => {
        if (items.length > 0) {
            setManualMode(true);
        }
    }, [items]);

    useEffect(() => {

        if (isNaN(tip) && !isEditingTip) {
            setTip(0);
        }
    }, [tip, isEditingTip]);

    useEffect(() => {
        if (isNaN(tipAmount) && !isEditingTip) {
            setTipAmount(0);
        }
    }, [tipAmount, isEditingTip]);


    const handleExpandItem = (index) => {
        if (index === selectedIndex) {
            setSelectedIndex(null);
        } else {
            setSelectedIndex(index);
        }
    };

    const handleRemoveItem = (index) => {
        const confirmed = window.confirm("Are you sure you want to delete this item?");
        if (confirmed) {
            setItems(items.filter((_, i) => i !== index));
            setSelectedIndex(null);
        }
    };

    const handleEdit = (index) => {
        setEditItemName(items[index]['name']);
        setEditItemPrice(items[index]['price']);
        setIsEditingCurrentSelected(true);
    };


    const handleSubmitEdit = (index,) => {
        items[index]['name'] = editItemName;
        items[index]['price'] = editItemPrice;
        setIsEditingCurrentSelected(false);
        setSelectedIndex(null);
    };



    function purebillTotal() {
        let total = 0;
        items.forEach(item => {
            total += parseFloat(item.price);
        });
        return total.toFixed(2);
    }

    function getServiceCharge() {
        if (serviceChargeAmount > 0) return serviceChargeAmount;
        return ((purebillTotal() * (1 - (globalDiscount / 100))) * (serviceCharge / 100)).toFixed(2);
    }

    function getExcludedTax() {
        if (excludedTaxAmount > 0) return excludedTaxAmount;
        return ((purebillTotal() * (1 - (globalDiscount / 100))) * (excludedTax / 100)).toFixed(2);
    }

    function getDiscountAmount() {
        return (purebillTotal() * (globalDiscount / 100)).toFixed(2);
    }

    function getTipAmount() {
        var amount = (((purebillTotal() * (1 - (globalDiscount / 100))) * (tip / 100))).toFixed(2);
        console.log("potato : " + isNaN(amount) + " " + amount);
        return isNaN(amount) ? 0 : amount;
    }

    function billTotal(serviceCharge, excludedTax, globalDiscount, items) {
        let total = purebillTotal();
        total = total - parseFloat(getDiscountAmount(globalDiscount, items)) + parseFloat(getServiceCharge(serviceCharge, globalDiscount, items)) + parseFloat(getExcludedTax(excludedTax, globalDiscount, items));
        total = total + parseFloat(getTipAmount())
        return total.toFixed(2);
    }

    const formatTip = (value) => {
        if (!isNaN(parseFloat(value))) {
            const rounded = parseFloat(value).toFixed(2);

            return parseFloat(rounded).toString();
        }
        else {
            return value
        }
    };


    return (
        <>

            <div className='billItemsConatinerCG'  >
                <div style={items.length > 0 ? { justifyContent: "flex-start", height: (isExtraInfo) ? '87%' : '91%' } : { justifyContent: "center", height: "100%" }} className='create-bill-Items-Content-Section'>

                    <ul style={{ listStyle: 'none', width: "100%" }}>
                        {items.map((item, index) => (
                            <ListItem item={item} index={index}
                                selectedIndex={selectedIndex}
                                isEditingCurrentSelected={isEditingCurrentSelected}
                                handleRemoveItem={handleRemoveItem}
                                handleEdit={handleEdit}
                                setEditItemName={setEditItemName}
                                setEditItemPrice={setEditItemPrice}
                                handleExpandItem={handleExpandItem}
                                handleSubmitEdit={handleSubmitEdit}
                                editItemName={editItemName}
                                editItemPrice={editItemPrice}
                                serviceCharge={serviceChargeAmount > 0 ? Math.round(100 * serviceChargeAmount / purebillTotal(), 2) : serviceCharge}
                                splitSCEqually={dontsplitServiceChargeEqually}
                                excludedTax={excludedTaxAmount > 0 ? Math.round(100 * excludedTaxAmount / purebillTotal(), 2) : excludedTax}
                                discount={globalDiscount}
                            />
                        ))}
                        <li ref={editSectionRef} className='billItemsControlButtons'>
                            <div className='billItemsButtonsVertical'>
                                {items.length > 0 && <p>You can still make changes </p>}
                                {items.length < 6 &&
                                    <button className={items.length > 0 ? 'billItemControlButtonSmall' : 'billItemControlButton'} type="button" onClick={() => setIsTakingBillPic(true)}>
                                        Scan Bill
                                    </button>
                                }
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'spaceBetween', width: '60%', margin: items.length > 0 ? "0" : "5%" }}>

                                </div>
                                {!manualMode ? (
                                    <button className={items.length > 0 ? 'billItemControlButtonSmall' : 'billItemControlButtonTrans'} type="button" onClick={() => setManualMode(true)}>
                                        Manual
                                    </button>
                                ) :
                                    renderEditSection()
                                }

                            </div>
                        </li>
                    </ul>

                </div>

                {items.length > 0 &&
                    <>
                        <div className='tip-Section'>
                            <div className='div-ForLine'></div>
                            <div style={{ display: "flex", justifyContent: "center", width: "90%", alignItems: "center" }}>
                                <div style={{ display: "flex", justifyContent: "space-between", width: "95%", alignItems: "center", paddingTop: "2%", paddingBottom: "2%" }}>
                                    <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                                        {isEditingTip ? (
                                            <input
                                                style={{ width: "80px" }}
                                                inputMode='decimal'
                                                min="0"
                                                onChange={(event) => setTip(event.target.value)}
                                                value={tip}
                                                onFocus={(event) => {
                                                    if (event.target.value === '0.00') {
                                                        setTip('');
                                                    }
                                                }}
                                                onBlur={(event) => {
                                                    if (event.target.value === '') {
                                                        setTip('0.00');
                                                    }
                                                }}
                                            />
                                        ) : (<h2 style={{ margin: "0" }}>{formatTip(tip)}</h2>)}
                                        <h2 style={{ margin: "0", paddingLeft: "7px" }}>%</h2>
                                    </div>
                                    <h2 style={{ margin: "0" }}>Tip</h2>
                                    <h2 style={{ margin: "0" }}>=</h2>
                                    {isEditingTip ? (
                                        <input
                                            style={{ width: "30%" }}
                                            inputMode='decimal'
                                            pattern="\d*(\.\d*)?"
                                            min="0"
                                            onChange={(event) => setTipAmount(event.target.value)}
                                            value={tipAmount}
                                            onFocus={(event) => {
                                                if (event.target.value === '0.00') {
                                                    setTipAmount('');
                                                }
                                            }}
                                            onBlur={(event) => {
                                                if (event.target.value === '') {
                                                    setTipAmount('0.00');
                                                }
                                            }}
                                        />
                                    ) : (<h2 style={{ margin: "0" }}>{isNaN(parseFloat(tipAmount)) ? tipAmount : parseFloat(tipAmount).toFixed(2)}</h2>)}
                                    <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", flexDirection: "row", width: "20%" }} >
                                        <button style={{ backgroundColor: isEditingTip ? "green" : "#1A1C33", display: "flex", justifyContent: "center", alignItems: "center", marginRight: "6px" }} onClick={() => { setIsEditingTip(!isEditingTip); if (!isEditingTip) editSectionRef.current.scrollIntoView({ behavior: 'smooth' }); }}>
                                            <img className='provsionalItemsButtons' style={{ height: "30px" }} src={require('../../assets/edit.png')} />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{ height: (isExtraInfo) ? '10%' : '9%', justifyContent: (isExtraInfo) ? 'flex-start' : 'flex-start' }} className='create-bill-Items-Information-Section'>
                            <div className='div-ForLine'></div>
                            <div className='serviceChargeAndTaxDiv'>
                                <ExtraTotalInfo serviceCharge={getServiceCharge()}
                                    excludedTax={getExcludedTax()}
                                    discount={getDiscountAmount()}
                                    discountPercentage={globalDiscount}
                                />
                            </div>

                            <div style={{ display: "flex", justifyContent: "center", width: "90%", alignItems: "center", marginTop: (isExtraInfo) ? '0%' : '3%' }}>

                                <div style={{ display: "flex", justifyContent: "space-between", width: "95%", alignItems: "center" }}>
                                    <div style={{ width: "55%", display: "flex", justifyContent: "space-between", alignItems: "center", flexDirection: "row" }}>
                                        <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                                            <h2 style={{ margin: "0px", fontSize: "170%" }}>Total</h2>
                                            <p style={{ margin: "0px", fontSize: "10px", paddingTop: "2%", paddingLeft: "4px", width: "20px" }}>Incl. Extras</p>
                                        </div>
                                        <h2 style={{ margin: "0px", fontSize: "170%" }}>{parseFloat(billTotal()).toFixed(2)}</h2>
                                    </div>
                                    <button
                                        className={`createGroupButton ${(items.length === 0) ? 'disabled' : ''}`}
                                        type="submit"
                                        style={{ fontSize: "150%" }}
                                        onClick={() => setShowConfirmCreationBox(true)}
                                        disabled={items.length === 0}
                                    >
                                        Create
                                    </button>
                                </div>
                            </div>

                        </div>
                    </>
                }

            </div>
        </>
    );
}


export default SetBillItems;