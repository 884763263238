import React, { useRef } from 'react';
import Webcam from 'react-webcam';
import Cropper from 'cropperjs';
import './style.css'
import FadeLoader from "react-spinners/FadeLoader";

export default function SelfCameraComponent({setIsTakingPic, setSelfImage}) {
  const webcamRef = useRef(null);

  const capture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    const image = new Image();
    image.src = imageSrc;
    image.onload = () => {
      // Add the image to the DOM first
      document.body.appendChild(image);
      // Then create the Cropper instance
      const cropper = new Cropper(image, {
        aspectRatio: 1,
        viewMode: 1,
        autoCropArea: 0.65,
        movable: false,
        cropBoxResizable: false,
        scalable: false,
        zoomable: false,
        crop: () => {
          const canvas = cropper.getCroppedCanvas();
          setSelfImage(canvas.toDataURL('image/jpeg'));
        }
      });
      cropper.crop();
    };
    setIsTakingPic(false);
  }


  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelfImage(reader.result);
        setIsTakingPic(false);
      };
      reader.readAsDataURL(file);
    }
  }

  return (
    <div className="cam_container">
        <FadeLoader color="white" style={{position: "absolute", top: "50%", left: "50%"}}/>
        <Webcam className="cam" ref={webcamRef} screenshotFormat="image/jpeg" videoConstraints={{facingMode: 'user'}}/>
        <div className="cam_overlay">
            <div className="cutout"></div>
        </div>
        <div className="cam_overlay_bottom">
            <button className="cam_button" onClick={() => {setIsTakingPic(false)}}>Cancel</button>
            <button className="cam_button_cap" onClick={capture}></button>
            <input
            style={{ display: 'none' }}
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            id="upload-button-file"
            />
            <button className="cam_button" onClick={() => document.getElementById("upload-button-file").click()}>Upload</button>
        </div>
        
    </div>
  );
};
