import React, { useState, useRef, useEffect } from 'react';
import '../style.css';
import SelfCameraComponent from "../../utils/SelfCamera/SelfCamera"
import ScaleLoader from "react-spinners/ScaleLoader";


export default function JoinGroupBox({joinGroup, updateLocalStorage, fetchFunction, setHasJoined}) {
    const [showjoinGroupBox, setShowjoinGroupBox] = useState(false);
    const [fullName, setFullName] = useState('');
    const [password, setPassword] = useState('');
    const [groupID, setGroupID] = useState(window.location.href.substr(window.location.href.length - 6));
    const [imageData, setImageData] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [isTakingSelfPic, setIsTakingSelfPic] = useState(false);

    const [isWaiting, setIsWaiting] = useState(false);

    const [visible, setVisible] = useState(false);


    useEffect(() => {
      setVisible(true);
      return () => {
        setVisible(false);
      };
    }, []);
    

    function handleFullNameChange(event) {
        setFullName(event.target.value);
    }

    function handlePasswordChange(event) {
        setPassword(event.target.value);
    }


    async function handleJoin(){
        //console.log("Attempting to join group")
        if(imageData === null) {
            setImageData("");
        }         
        setIsWaiting(true);
        
        await joinGroup(groupID, fullName, password, imageData,  setShowPassword, setErrorMessage, updateLocalStorage);
        //console.log("Current Error Message: " + errorMessage);
        if(errorMessage === "") {
            setShowjoinGroupBox(false);
            setHasJoined(true);
            await fetchFunction();
            setIsWaiting(false);
            
        }
    }

    return (
        <>
            {isTakingSelfPic && 
                <div style={{position: "absolute", top: "0px", left: "0px", width: "100%", height: "100%", zIndex: "10"}}>                   
                    <SelfCameraComponent setIsTakingPic={setIsTakingSelfPic} setSelfImage={setImageData}/> 
                </div>             
            }
            <div className={`disabledBackground ${visible ? 'greyedOutvisible' : ''}`}>
            </div>
            <div className="joinGroupBox">
                    <div className="joinGroupBox-content">
                        <div>
                            <h1>Join Group</h1>
                        </div>
                        <div className="joinGroupBox-content-top">
                            <div style={{width: "20%"}}></div>
                            <div className="user-picture-circle" style={{ backgroundImage: `url(${imageData})`, width: "45%", display: "flex", justifyContent: "center", alignContent: "center"}}>
                                {!imageData && fullName.length >= 2 && fullName.split(' ').map((name) => name[0]).slice(0, 2).join('').toUpperCase()}
                            </div>
                            <div style={{width: "20%", display: "flex", justifyContent: "center", alignContent: "center"}}>
                                {/* <button  onClick={() => setIsTakingSelfPic(true)} className='takeUserImageButton'>
                                    <img style={{width: "30px", height: "auto"}} src={require('../../assets/camera.png')} alt="Take a picture" />
                                </button>                                          */}
                            </div>
                            
                        </div>
                        <div className="joinGroupBox-content-middle">
                            <input style={{width: "100%"}} type="text" placeholder="Name" onChange={handleFullNameChange} />
                        </div>
                        { showPassword &&
                            <div className="joinGroupBox-content-middle">
                                <input style={{width: "100%"}} type="password" placeholder="Password" onChange={handlePasswordChange}/>
                            </div>
                        }
                        <div className="joinGroupBox-content-bottom">
                            <button style={{width: "100%", display: "flex", justifyContent: "center", alignContent: "center"}} onClick={handleJoin}>
                            {isWaiting && 
                                <ScaleLoader color="white" height="19"/>    
                            }
                            {!isWaiting &&
                            <>
                            Join
                            </>
                            }
                                
                            </button>
                        </div>
                        { showPassword && errorMessage == "" &&
                            <h3>This group requires a password</h3>
                        }
                        { errorMessage != "" &&
                            <div className="errorMessage">
                                <h3>{errorMessage}</h3>
                            </div>
                        }
                    </div>
                    
            </div>

            
            
        </>
    );
}
