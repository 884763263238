import React, { useState, useRef, useEffect } from 'react';
import '../../style.css';
import Switch from 'react-switch';


export function SetServiceChargeOptionsBox({ setserviceCharge, serviceCharge, dontsplitServiceChargeEqually, serviceChargeAmount, setserviceChargeAmount, setDontSplitServiceChargeEqually, setScNo, step, goNext}){
    
    function handleChangeEqually() {
        if (dontsplitServiceChargeEqually) {
            window.confirm("Are you sure you want to split the service charge equally? This means that all group members will pay the same share of the service charge, regardless of what they ordered rather than paying service charge as a percentage of the itesm they had claimed.")
            setDontSplitServiceChargeEqually(false)
        } else {
            setDontSplitServiceChargeEqually(true)
        }

    }

    useEffect(() => {
        if ((serviceCharge == "" || serviceCharge == "0")  && (serviceChargeAmount == "" || serviceChargeAmount == "0")){
            setScNo(true)
        }else{
            setScNo(false)
        }
      }, [serviceCharge, serviceChargeAmount]);


    return (
        <div className='OptionsBox'>   
            <div className='tascInputsContainer' style={{height: "auto"}}>
                    <div className='tascInputContainer' style={{width: "100%"}}>
                        <div className='tascInputFeild'>
                            <h2>Service Charge Percentage  </h2>
                            <input type="text"
                                style={{ width: "27%", textAlign: "right", backgroundColor: (serviceChargeAmount != "" &&  serviceChargeAmount != "0") ? 'grey' : 'white', transition: 'background-color 0.5s ease' }}
                                value={serviceCharge}
                                inputMode='decimal'
                                onChange={event => setserviceCharge(event.target.value)}
                                onKeyPress={(event) => {
                                    const regex = /^(\d*\.?\d{0,2}|\d+)$/;
                                    const value = event.target.value + event.key;
                                    if (!regex.test(value) || Number(value) > 99) {
                                        event.preventDefault();
                                    }
                                }}
                                disabled={(serviceChargeAmount != "" &&  serviceChargeAmount != "0")}
                            />
                        </div>

                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'spaceBetween', width: '60%' }}>
                        <hr style={{ width: '40%', borderColor: 'grey', borderWidth: '1px' , borderTop: "none"  }} />
                        <p>OR</p>
                        <hr style={{ width: '40%', borderColor: 'grey', borderWidth: '1px' , borderTop: "none" }} />
                    </div>
                    <div className='tascInputContainer' style={{width: "100%"}}>
                        <div className='tascInputFeild'>
                            <h2>Service Charge Amount  </h2>
                            <input type="text"
                                style={{ width: "27%", textAlign: "right", backgroundColor: (serviceCharge != "" && serviceCharge != "0") ? 'grey' : 'white', transition: 'background-color 0.5s ease' }}
                                value={serviceChargeAmount}
                                inputMode='decimal'
                                onChange={event => setserviceChargeAmount(event.target.value)}
                                onKeyPress={(event) => {
                                    const regex = /^(\d*\.?\d{0,2}|\d+)$/;
                                    const value = event.target.value + event.key;
                                    if (!regex.test(value) || Number(value) < 0) {
                                        event.preventDefault();
                                    }
                                }}
                                disabled={(serviceCharge != "" && serviceCharge != "0")}
                            />
                        </div>

                    </div>
                    <hr style={{ width: '60%', borderColor: 'grey', borderWidth: '1px', marginTop: "12px" , borderTop: "none" }} />
                    <div className='tascInputContainer' style={{width: "100%"}}>
                        <div className='tascInputFeild'>
                            <h3>Split Based on Items Claimed</h3>
                            <Switch onChange={handleChangeEqually} checked={dontsplitServiceChargeEqually} />
                        </div>
                    </div>
                </div>
            
        </div>
    )
}