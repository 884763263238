import "./style.css";
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { MakeUserAdmin, KickUser } from '../../ApiCalls/Api';
import AnimateHeight from "react-animate-height";
import ClaimerIcon from '../../ClaimerIcon/ClaimerIcon';
import ScaleLoader from "react-spinners/ScaleLoader";


export default function UserInfo({roomID, index, user, users, items, currentUserName, selectedIndex, setSelectedIndex, setShowApplied, queryBackend}) {
   
    const [isWaitingMakingAdmin, setIsWaitingMakingAdmin] = useState(false);
    const [isWaitingKickingUser, setIsWaitingKickingUser] = useState(false);

    const numberOfItemsClaimed = useMemo(() => {
        let count = 0;
        items.forEach(item => {
            if (item.claimees.includes(user)) {
                count++;
            }
        });
        return count;
    }, [items, user]);

    const claimedItems = useMemo(() => {
        let claimedItems = [];
        items.forEach(item => {
            if (item.claimees.includes(user)) {
                claimedItems.push(item);
            }
        });
        return claimedItems;
    }, [items, user]);

    const isCurrentUserAdmin = useMemo(() => {
        return users[currentUserName][2];
    }, [users, currentUserName]);


    async function handleRemoveUser(user) {
        if (window.confirm("Are you sure you want to leave the group?")) {
            setIsWaitingKickingUser(true);
            await KickUser(window.location.href.substr(window.location.href.length - 6), user);
            await queryBackend();
            setIsWaitingKickingUser(false);
            
        }
       
    }

    async function handleMakeUserAdmin(user) {
        setIsWaitingMakingAdmin(true);
        await MakeUserAdmin(roomID ,user);
        setIsWaitingMakingAdmin(false);
        setShowApplied(true);
    }

    function handleClick() {
        //console.log("Selected index: " + selectedIndex + " index: " + index );
        if (selectedIndex == index) {
            setSelectedIndex(-1);
        } else {
            setSelectedIndex(index);
        }
    }

    const [height, setHeight] = useState("auto");

    useEffect(() => {
        const element = document.getElementById(`user${index}`);
        const width = element.clientWidth;
        const clone = element.cloneNode(true);
        clone.style.width = width + "px";
        clone.style.height = "auto";
        clone.style.position = "absolute";
        clone.style.visibility = "hidden";
        element.parentNode.appendChild(clone);
        const newHeight = clone.clientHeight + 3;
        element.parentNode.removeChild(clone);
        //console.log("New height: " + newHeight);
        setHeight(newHeight);
    }, [selectedIndex, index]);
      

    return (

        <li  className="user-list-item">
            <AnimateHeight className="animatedUserDiv" duration={500} height={height} style={{width : "100%"}}>
                <div id={"user" + index} style={{width : "100%"}}>
                <div className="user-list-item-top">
                    <div className="user-list-item-left">
                        <div onClick={() => handleClick()} className="user-list-icon">
                                <ClaimerIcon users={users} claimee={user}/>
                            </div>
                    </div>
                    <div className="user-list-item-right">
                        <div onClick={() => handleClick()} className="user-name">
                            <h3>{user}{user==localStorage.getItem("username") ? " (You)" : ""}</h3>
                        </div>
                        <div onClick={() => handleClick()} className="user-list-claimed-items-header">
                            <p>Has claimed {numberOfItemsClaimed} items {selectedIndex == index ? ":" : ""}</p>
                        </div>
                        {selectedIndex == index &&
                            <div className="user-list-claimed-bill-items">
                                <ul style={{ listStyleType: 'none', padding: "0", width: "100%" }}>
                                    {claimedItems.map((item, index) => {
                                        return (
                                            <li style={{width: "100%"}}>
                                                <div className="user-list-bill-item">
                                                    <div className="user-list-bill-item-horizantal">
                                                    <h4>{item.itemName}</h4>
                                                    <h4>{parseFloat(item.price).toFixed(2)}</h4>
                                                    </div>
                                                </div>
                                            </li>
                                        )
                                    })}

                                </ul>
                            </div>
                        }
                    </div>
                </div>
                <div className="user-list-item-bottom">
                {selectedIndex == index &&
                    <>
                    {/* {!isCurrentUserAdmin && user != currentUserName &&
                        <div>
                            <p style={{color : "red", textAlign: "center"}}>You must be an admin to do this</p>
                        </div>
                    } */}
                    {user != currentUserName && isCurrentUserAdmin &&
                    <div className="user-list-buttons">
                            <button style={{display: "flex", justifyContent: 'center', alignItems: "center"}} onClick={() => (handleMakeUserAdmin(user))} disabled={!isCurrentUserAdmin} className={`user-list-button ${(!isCurrentUserAdmin) ? 'disabled' : ''}`}>{isWaitingMakingAdmin ? <ScaleLoader color="white" height="14px"/>  : <>Make Admin</>}</button>
                            <button style={{display: "flex", justifyContent: 'center', alignItems: "center"}} onClick={() => handleRemoveUser(user)} disabled={!isCurrentUserAdmin} className={`user-list-button ${(!isCurrentUserAdmin) ? 'disabled' : ''}`}>{isWaitingKickingUser ? <ScaleLoader color="white" height="14px"/>  : <>Kick</>}</button>
                    </div>
                    }
                    </>
                }
                </div>
                </div>
            </AnimateHeight>
        </li>
                    
    );

}