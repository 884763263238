import "./style.css";
import React, { useEffect, useState } from 'react';
import OwedToYou from '../OwedToYou/OwedToYou';


export default function SplitComplete({ total, isBirthday, users, items, getTotalFunc, birthdayUsers }) {
  const [visible, setVisible] = useState(false);

  const [showOwedToYou, setShowOwedToYou] = useState(false);

  useEffect(() => {
    setVisible(true);
    return () => {
      setVisible(false);
    };
  }, []);

  return (
    <>
      <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1706712240098107" crossorigin="anonymous"></script>
      <div className={`disabledBackground ${visible ? 'greyedOutvisible' : ''}`}></div>

      <div className="split-complete-container">

        <img className="blur"
          src={require('../../assets/blurs/blur9.png')}
          style={{
            position: "absolute",
            top: "10%",
            left: "0%",
            zIndex: "0",
            height: "80%",
            width: "auto"
          }}>
        </img>
        <img className="blur"
          src={require('../../assets/blurs/blur2.png')}
          style={{
            position: "absolute",
            top: "20%",
            right: "0%",
            zIndex: "0",
            height: "100%",
            width: "auto"
          }}>
        </img>

        <div className="split-complete-top">
          <h2>You've Gone Dutch!</h2>
          <h3>Bill Succesfully Split</h3>
        </div>

        <div className="split-complete-mid">
          <div style={{ width: "100%", height: "40%" }}>
            <h3 style={{ marginTop: "14px" }}>You are paying</h3>
            <h1>{isBirthday ? "00.00" : total}</h1>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'spaceBetween', width: '80%' }}>
            <hr style={{ width: '40%', borderColor: 'grey', borderWidth: '1px', borderTop: "none" }} />
            <p>OR</p>
            <hr style={{ width: '40%', borderColor: 'grey', borderWidth: '1px', borderTop: "none" }} />
          </div>
          <p>If you paid for the whole bill</p>
          <p>You can see what others owe you</p>
          <button
            style={{ fontSize: "200%", padding: "5%" }}
            onClick={() => setShowOwedToYou(true)}>
            See Owed To You
          </button>


        </div>
        <div className="split-complete-bottom" >
          <div style={{ height: "20%", paddingTop: "8px" }}>
            <h3>Download for next time</h3>
          </div>
          <p>Coming Soon</p>

          {/* <div style={{ height: "60%", display: "flex", justifyContent: "space-between", alignItems: "center", flexDirection: "column"}}>
            <button style={{
              height: "45%",
              padding: "0",
              border: "none",
              background: "none"
            }}>
              <img src={require('../../assets/appleAppStore.png')} style={{ height: "100%", aspectRatio: "1084/326" }} />
            </button>

            <button style={{
              height: "45%",
              padding: "0",
              border: "none",
              background: "none",
            }}>
              <img src={require('../../assets/googlePlay.png')} style={{ height: "100%", aspectRatio: "1084/326" }} />
            </button>
          </div> */}



        </div>






      </div>
      {showOwedToYou && <OwedToYou  setShow={setShowOwedToYou} 
                                    users={users}
                                    items={items} 
                                    getTotalFunc={getTotalFunc} 
                                    birthdayUsers={birthdayUsers} />
      }
    </>
  );
}
