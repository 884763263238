import React, { useState, useRef, useEffect } from 'react';
import '../../style.css';
import Switch from 'react-switch';
import { ChangeTax } from '../../ApiCalls/Api';
import ScaleLoader from "react-spinners/ScaleLoader";

export default function SetTaxOptionsBox({ total, tax, setShowApplied, queryBackend }) {

  const [taxNew, setTaxNew] = useState(tax);
  const [taxAmountNew, setTaxAmountNew] = useState(formatNumber(total * (tax / 100)));
  const [isIncluded, setIsIncluded] = useState(taxNew == 0);

  const [isWaiting, setIsWaiting] = useState(false);

  async function handleSetTax() {
    if (window.confirm("Are you sure you want to change the tax to " + (isIncluded ? "0" : taxNew) + "% ?")) {
      setIsWaiting(true);
      await ChangeTax(isIncluded ? "0" : taxNew);
      await queryBackend();
      setIsWaiting(false);
      setShowApplied(true);
    }
  }

  function formatNumber(n) {
    if (Math.floor(n) === n) return n.toString();
    if ((n * 10) % 1 === 0) return n.toFixed(1);
    return n.toFixed(2);
  }

  function changeTaxPercentage(input) {
    setTaxNew(input);
    setTaxAmountNew(formatNumber(total * (input / 100)));
  }

  function changeTaxAmount(input) {
    setTaxAmountNew(input);
    setTaxNew(formatNumber(input / total * 100));
  }

  return (
    <div className='OptionBox' >
      <div className='ServiceChargeMiddle'>
        <h3 style={{ margin: "0%", marginBottom: "10px", textAlign: "center" }}>Tax is already included in prices</h3>
        <Switch onChange={() => { setIsIncluded(!isIncluded) }} checked={isIncluded} />
      </div>
      {!isIncluded &&
        <div className='tascInputsContainer' style={{ margin: "15px 0px" }}>
          <div className='tascInputContainer' style={{ width: "100%" }}>
            <div className='tascInputFeild'>
              <h2 style={{ margin: "0%", marginBottom: "10px", textAlign: "left" }}>Tax Percentage   </h2>
              <input type='number' pattern="\d*(\.\d*)?" min="0" max="100"
                style={{ width: "35%", textAlign: "right" }}
                value={taxNew}
                inputMode='decimal'
                onChange={event => changeTaxPercentage(event.target.value)}
                onKeyPress={(event) => {
                  const regex = /^(\d*\.?\d{0,2}|\d+)$/;
                  const value = event.target.value + event.key;
                  if (!regex.test(value) || Number(value) > 99) {
                    event.preventDefault();
                  }
                }}
              />
            </div>

          </div>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'spaceBetween', width: '80%' }}>
            <hr style={{ width: '40%', borderColor: 'grey', borderWidth: '1px', borderTop: "none" }} />
            <p>OR</p>
            <hr style={{ width: '40%', borderColor: 'grey', borderWidth: '1px', borderTop: "none" }} />
          </div>
          <div className='tascInputContainer' style={{ width: "100%" }}>
            <div className='tascInputFeild'>
              <h2 style={{ margin: "0%", marginBottom: "10px", textAlign: "left" }}>Tax Amount   </h2>
              <input type='number' pattern="\d*(\.\d*)?" min="0"
                style={{ width: "35%", textAlign: "right" }}
                value={taxAmountNew}
                inputMode='decimal'
                onChange={event => changeTaxAmount(event.target.value)}
                onKeyPress={(event) => {
                  const regex = /^(\d*\.?\d{0,2}|\d+)$/;
                  const value = event.target.value + event.key;
                  if (!regex.test(value) || Number(value) < 0) {
                    event.preventDefault();
                  }
                }}
              />
            </div>

          </div>

        </div>
      }

      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <button
          style={{
            display: "flex",
            justifyContent: 'center',
            alignItems: "center",
            width: "100%",
            marginBottom: "20px"
          }}
          onClick={() => handleSetTax()}
        >
          {isWaiting ? <ScaleLoader color="white" height="19px" /> : <>Apply</>}
        </button>
      </div>
    </div>
  )
}